import React from 'react';

import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';

import { UpiPage } from './pages';
import * as pages from './pages';
import * as paths from './pathConstants';
import { analyticsTrackPageAction } from './redux/actions/analytics.ninja';

export const RouteWithSubRoutes = ({ path, exact, ...args }) => <Route path={path} exact={exact} {...args} />;

const withPageTracking = compose(
  connect((state) => ({}), {
    trackPage: analyticsTrackPageAction,
  }),
  lifecycle({
    componentDidMount() {
      this.props.trackPage();
    },
  }),
);

const route = (path, component, exact = false) => ({
  path,
  component: compose(withPageTracking)(component),
  exact,
});

const exactRoute = (path, component) => route(path, component, true);

// IMPORTANT! Always add exact routes first
export const routes = [
  exactRoute(paths.INDEX, pages.PaymentSelectionPage),
  exactRoute(paths.PAYMENT_SELECTION, pages.PaymentSelectionPage),
  exactRoute(paths.PAYMENT_RESULT, pages.PaymentStatusContainer),
  exactRoute(paths.INTERSTITIAL, pages.InterstitialPage),
  exactRoute(paths.BANK_TRANSFER_RESULT, pages.BankTransferResultPage),
  exactRoute(paths.OVER_THE_COUNTER_RESULT, pages.OverTheCounterResultPage),
  exactRoute(paths.UPI_TRANSFER, UpiPage),
  exactRoute(paths.ERROR, pages.ErrorPage),
  route(paths.STORED_CARD, pages.PaymentSelectionPage),
  route(paths.CARD, pages.CreditCardContainer),
  route(paths.ABOUT, pages.AboutPage),
  route(paths.INSTANT_BANK_TRANSFER, pages.InstantBankTransferContainer),
  route(paths.SMS, pages.SmsPage),
  route(paths.MAINTENANCE_PAGE, pages.MaintenancePage),
  route(paths.OVER_THE_COUNTER, pages.PaymentSelectionPage),
  route(paths.GENERIC, pages.GenericPage),
];
