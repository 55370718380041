import { useState } from 'react';

import { H2 } from '@olxui/core';
import { Banner, MessageStatus } from '@olxui/core/dist/core/Banner/Banner';
import { SegmentedControl } from '@olxui/core/dist/core/SegmentedControl/SegmentedControl';

import AboutLink from '../../components/AboutLink';
import { getMessage } from '../../helpers/lexemes';
import { usePaymentStatusRequest } from '../../hooks';
import { VpaOption, QRCodeOption } from './components';
import { useUpiTransferInitialize, useUpiTransferExecute } from './hooks';
import { StyledPageHeader, StyledBox, StyledBoxContent, StyledBoxTitle } from './styled';

export const UpiPage = () => {
  const { data: initializeData, error: initializeError, formattedPrice } = useUpiTransferInitialize();

  const {
    data: executeData,
    error: executeError,
    handleExecuteRequest,
  } = useUpiTransferExecute(initializeData?.options?.[0]?.uri || '');

  const { data: paymentStatusData, handleGetStatusRequest } = usePaymentStatusRequest(executeData?.action?.uri || '');

  const segmentOptions = {
    upi: {
      id: 'ID_VPA',
      content: 'UPI ID/VPA',
    },
    qrcode: {
      id: 'QR_CODE',
      content: 'QR Code',
    },
  };

  const [segmentOption, setSegmentOption] = useState<string | number>(segmentOptions.upi.id);

  const isRejected = paymentStatusData?.status === 'REJECTED';

  const handleSegmentedChange = (selectedOption: string | number) => {
    setSegmentOption(selectedOption);

    if (segmentOption !== selectedOption && selectedOption === segmentOptions.qrcode.id) {
      handleExecuteRequest(selectedOption);
    }
  };

  const handleConfirmedPayment = () => {
    window.location = paymentStatusData?.completionUrl as any;
  };

  if (paymentStatusData?.status === 'CAPTURED') {
    handleConfirmedPayment();
  }

  return (
    <>
      <StyledPageHeader>
        <H2>UPI</H2>
      </StyledPageHeader>

      <StyledBox>
        <StyledBoxContent>
          {initializeError && (
            <Banner type={MessageStatus.ERROR} minHeight="unset">
              {getMessage(`UPI.errorMessage`)}
            </Banner>
          )}

          <StyledBoxTitle> Use your UPI ID or scan QR code </StyledBoxTitle>

          <SegmentedControl
            options={[segmentOptions.upi, segmentOptions.qrcode]}
            selectedId={segmentOption}
            onSelect={handleSegmentedChange}
          />

          {segmentOption === segmentOptions.upi.id && (
            <VpaOption
              hasError={!!executeError}
              price={formattedPrice}
              segmentOption={segmentOption}
              onExecute={handleExecuteRequest}
              onGetStatus={handleGetStatusRequest}
              isRejected={isRejected}
            />
          )}

          {segmentOption === segmentOptions.qrcode.id && (
            <QRCodeOption
              hasError={!!executeError}
              qrCodeData={executeData?.action?.context?.qrCodeData || ''}
              segmentOption={segmentOption}
              onGetStatus={handleGetStatusRequest}
              onExecute={handleExecuteRequest}
              isRejected={isRejected}
            />
          )}

          <AboutLink type="UPI" />
        </StyledBoxContent>
      </StyledBox>
    </>
  );
};
