import styled from '@emotion/styled';
import { Box } from '@olxui/core/dist/core/Box/Box';

export const StyledBox = styled(Box)`
  justify-content: center;
  & > svg {
    width: auto;
    margin: 10px 0;
  }
`;

export const StyledBoxGenerateQrCode = styled(Box)`
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;