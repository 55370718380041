import { FC, useState, createContext } from 'react';

import { ProgressLoader } from '../../components';
import FullPageLoader from '../../components/FullPageLoader';

type TypeComponentOptions = 'full' | 'progress';

type LoaderContextProviderProps = {
  isLoading: Boolean;
  toggleLoading: () => void;
  handleTypeChange: (type: TypeComponentOptions) => void;
};

export const LoaderContext = createContext<LoaderContextProviderProps>({} as any);

export const LoaderContextProvider: FC = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState<TypeComponentOptions>('full');

  const toggleLoading = () => setLoading(!isLoading);
  const handleTypeChange = (type: TypeComponentOptions) => setLoadingType(type);

  return (
    <LoaderContext.Provider value={{ isLoading, toggleLoading, handleTypeChange }}>
      {<FullPageLoader isOpen={loadingType === 'full' && isLoading} />}
      {<ProgressLoader isOpen={loadingType === 'progress' && isLoading} />}
      {children}
    </LoaderContext.Provider>
  );
};
