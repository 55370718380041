// @flow

import { getOr } from 'lodash/fp';
import { createSelector } from 'reselect';

import { queryParams } from './routing';

export const previewFlags = createSelector(queryParams, (params) => {
  if (!(params && params.preview)) {
    return {};
  }

  return params.preview.split(',').reduce((acc, flag) => {
    acc[flag] = true;
    return acc;
  }, {});
});

export const isTokenizationPreview = createSelector(previewFlags, getOr(false, 'tokenization'));

export const previewHeaders = createSelector(queryParams, getOr('', 'previewHeaders'));

export const testCase = createSelector(queryParams, getOr('', 'testCase'));
