const defaultState = {
  paymentInfo: false,
  loading: false,
  error: false,
};

const bankTransfer = (state = defaultState, action) => {
  switch (action.type) {
    case 'XHR_START':
      return Object.assign({}, state, { loading: true, error: false });
    case 'XHR_SUCCESS':
      return Object.assign({}, state, {
        paymentInfo: action.result,
        loading: false,
        error: false,
      });
    case 'XHR_ERROR':
      return Object.assign({}, state, { loading: false, error: true });
    default:
      return state;
  }
};

export default bankTransfer;
