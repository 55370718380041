export const breakpoints = {
  mobile: '320px',
  phablet: '480px',
  tablet: '769px',
  desktop: '1072px',
  desktopLarge: '1264px',
};

export default {
  gt: (breakpoint) => `@media (min-width: ${breakpoints[breakpoint] || breakpoint})`,
  lt: (breakpoint) => `@media (max-width: ${breakpoints[breakpoint] || breakpoint})`,
};
