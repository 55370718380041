import { useEffect, useState } from 'react';

import { Banner, MessageStatus } from '@olxui/core/dist/core/Banner/Banner';
import ButtonSecondaryDark from '@olxui/core/dist/core/Button/ButtonSecondaryDark';
import QRCode from 'react-qr-code';

import { getMessage } from '../../../../helpers/lexemes';
import { CountdownTimer } from '../CountdownTimer';
import { StyledBox, StyledBoxGenerateQrCode } from './styled';
import { QRCodeOptionProps } from './types';

const defaultTime = 300;
const intervalTime = 2000;

export const QRCodeOption = ({
  qrCodeData,
  onGetStatus,
  hasError,
  segmentOption,
  onExecute,
  isRejected,
}: QRCodeOptionProps) => {
  const [isFinished, setFinished] = useState(false);

  useEffect(() => {
    if (hasError) return;

    const interval = setInterval(onGetStatus, intervalTime);

    return () => clearInterval(interval);
  }, [onGetStatus]);

  const handleGeneratenewQrCode = () => onExecute(segmentOption);

  const handleFinished = () => setFinished(true);

  if (hasError) {
    return (
      <Banner type={MessageStatus.ERROR} minHeight="unset">
        {getMessage(`UPI.qrcode.errorMessage`)}
      </Banner>
    );
  }

  if (isFinished || isRejected) {
    return (
      <StyledBoxGenerateQrCode>
        {isFinished && (
          <Banner type={MessageStatus.WARNING} minHeight="unset">
            {getMessage(`UPI.qrcode.timesup`)}
          </Banner>
        )}

        {isRejected && (
          <Banner type={MessageStatus.ERROR} minHeight="unset">
            {getMessage(`UPI.qrcode.paymentRejected`)}
          </Banner>
        )}

        <ButtonSecondaryDark size="small" onClick={handleGeneratenewQrCode}>
          {getMessage('UPI.qrcode.newQrCodeButton')}
        </ButtonSecondaryDark>
      </StyledBoxGenerateQrCode>
    );
  }

  return (
    <>
      <StyledBox>
        <QRCode data-testid="qrcode" size={128} value={qrCodeData} />
      </StyledBox>

      <CountdownTimer
        timeInSeconds={defaultTime}
        activeMessage={getMessage('UPI.qrcode.progressMessage')}
        secondsText={getMessage('Global.seconds')}
        minutesText={getMessage('Global.minutes')}
        onFinished={handleFinished}
      />
    </>
  );
};
