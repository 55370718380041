// @flow
import track, { MethodTypeEnum, EventNameEnum } from '../../../analytics/service';

export const trackGooglePayDisplayed = () =>
  track(EventNameEnum.METHOD_DISPLAYED, {
    method_type: MethodTypeEnum.GOOGLE_PAY,
  });

export const trackGooglePaySelected = (attemptId) =>
  track(EventNameEnum.PAYMENT_SELECTION_METHOD_SELECTED, {
    back_end_attempt_id: attemptId,
    method_type: MethodTypeEnum.GOOGLE_PAY,
  });
