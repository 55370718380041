export default async (locale = 'en', fallback = 'en', type) => {
  try {
    // try to load specific locale
    try {
      const content = await import(`./${type}/${locale}`);
      return content.default || content;
    } catch (error) {
      // try to load generic language
      const content = await import(`./${type}/${fallback}`);
      return content.default || content;
    }
  } catch (error) {
    // fallback to english
    const content = import(`./${type}/en`);
    return content.default || content;
  }
};
