import { matchPath } from 'react-router-dom';
import { createSelector, createStructuredSelector } from 'reselect';

import {
  CARD,
  OVER_THE_COUNTER_RESULT,
  PAYMENT_RESULT,
  PAYMENT_SELECTION,
  BANK_TRANSFER_RESULT,
  INTERSTITIAL,
} from '../pathConstants';
import { flavors } from './paymentMethods';
import { interstitialVisible } from './paymentStatus';
import { isInErrorPath, isInInterstitialPath } from './routing';

const pathname = () => window.location.pathname;

export const showHeaderControls = createSelector(
  pathname,
  interstitialVisible,
  (path, isInterstitial) => !isPage(path, PAYMENT_RESULT) || isInterstitial,
);

export const showHeader = createSelector(
  pathname,
  isInErrorPath,
  (path, isError) =>
    !isPage(path, PAYMENT_RESULT) &&
    !isError &&
    !isPage(path, INTERSTITIAL) &&
    !isPage(path, BANK_TRANSFER_RESULT) &&
    !isPage(path, OVER_THE_COUNTER_RESULT),
);

export const showBackButton = createSelector(
  isInErrorPath,
  isInInterstitialPath,
  (isError, isInterstitial) => !(isError || isInterstitial),
);

export const shouldUseCancelUrl = createSelector(
  pathname,
  flavors,
  (path, flavors) => isPage(path, PAYMENT_SELECTION) || (isPage(path, CARD) && hasPSFlavor(flavors)),
);

export const shouldRedirectToPaymentMethodsPage = createSelector(
  pathname,
  flavors,
  (path, flavors) => isPage(path, OVER_THE_COUNTER_RESULT) || (isPage(path, CARD) && !hasPSFlavor(flavors)),
);

export default createStructuredSelector({
  showHeader,
  showHeaderControls,
  showBackButton,
  shouldUseCancelUrl,
  shouldRedirectToPaymentMethodsPage,
});

function hasPSFlavor(flavors = []) {
  return flavors.includes('PRE_SELECTED_METHOD_CARD');
}

function isPage(path, expected) {
  return Boolean(matchPath(path, { path: expected, exact: true }));
}
