import { put, select, takeLatest } from 'redux-saga/effects';

import * as concreteApi from '../../api';
import { paymentId as paymentIdSelector } from '../../selectors/global';
import { mbtStartAction, mbtSuccessAction, mbtErrorAction } from '../actions/manualBankTransfer';
import { psSetMethodAction } from '../actions/paymentStatus';
import * as paymentFlowService from './paymentFlowService';

function* initialize({ api, paymentFlow }) {
  yield put(mbtStartAction());

  const paymentId = yield select(paymentIdSelector);

  try {
    const { result, json } = yield api.manualBankTransfer.initialize({
      paymentId,
    });
    if (json) {
      const successAction = mbtSuccessAction(json);
      yield put(successAction);
      yield paymentFlow.redirectViaFormSubmit(successAction);
    } else {
      yield put(mbtErrorAction(result));
    }
  } catch (err) {
    yield put(mbtErrorAction(err));
  }
}

const selectManualBankTransferMethodAction = ({ type, payload }) =>
  type === psSetMethodAction.toString() && payload.methodType === 'BANK_TRANSFER';

const makeMainSaga = ({ behavior, ...params }) =>
  function* manualBankTransferSaga() {
    yield takeLatest(selectManualBankTransferMethodAction, behavior.initialize, params);
  };

export default makeMainSaga({
  api: concreteApi,
  behavior: {
    initialize,
  },
  paymentFlow: paymentFlowService,
});

export const behaviorForTesting = {
  initialize,
  makeMainSaga,
};
