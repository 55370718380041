// @flow
import { flow, get, getOr, map, split, lowerCase } from 'lodash/fp';
import { matchPath } from 'react-router';
import { createSelector } from 'reselect';

import getSearchParams from '../helpers/getSearchParams';
import * as paths from '../pathConstants';

export const location = getOr({}, 'router.location');

export const search = get('router.location.search');

export const queryParams = createSelector(location, (loc) => getSearchParams(loc.search || window.location.search));

export const cardTokenId = createSelector(queryParams, get('cardTokenId'));

export const pathname = createSelector(location, get('pathname'));

const checkExactPath = (targetPath) =>
  createSelector(pathname, (pathname) => {
    const match = matchPath(pathname, targetPath);
    return match && match.isExact;
  });

export const isInInterstitialPath = checkExactPath(paths.INTERSTITIAL);
export const isInStoredCardPath = checkExactPath(paths.STORED_CARD);
export const isInPaymentResultPath = checkExactPath(paths.PAYMENT_RESULT);
export const isInErrorPath = checkExactPath(paths.ERROR);

export const preview = createSelector(queryParams, flow(getOr('', 'preview'), split(','), map(lowerCase)));
