// @flow
import { flow, filter, get, lowerCase, replace } from 'lodash/fp';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as concreteApi from '../../api';
import creditCardType from '../../helpers/creditCardType';
import { combineLatest } from '../../helpers/saga';
import * as paths from '../../pathConstants';
import { paymentId as paymentIdSelector } from '../../selectors/global';
import { pathname as pathnameSelector, preview as previewSelector } from '../../selectors/routing';
import { applicationInitializedAction, clearPersistenceState } from '../actions/global';
import { pmErrorAction, pmRequestAction, pmStartAction, pmSuccessAction } from '../actions/paymentMethods';
import { psSetMethodAction } from '../actions/paymentStatus';
import {
  ccTokenisedPaymentMethodRemoveConfirmAction,
  ccTokenisedPaymentMethodRemoveStartAction,
  ccTokenisedPaymentMethodRemoveSuccessAction,
  ccTokenisedPaymentMethodRemoveErrorAction,
} from '../actions/tokenisedPayment';
import * as paymentMethodsAnalyticsService from './analytics/paymentMethodsAnalyticsService';
import * as sharedAnalyticsService from './analytics/shared';
import * as paymentFlowService from './paymentFlowService';

const METHODS_IN_PREVIEW = [];

const isMethodInPreview = (method) => !!METHODS_IN_PREVIEW.length && METHODS_IN_PREVIEW.indexOf(method) !== -1;

const onlyReleasedMethods = (preview) =>
  filter(
    flow(
      get('methodType'),
      lowerCase,
      replace(/\W/g, ''),
      (methodType) => !isMethodInPreview(methodType) || preview.indexOf(methodType) !== -1,
    ),
  );

export function* getMethods({ api, paymentFlow }) {
  const pathname = yield select(pathnameSelector);
  yield put(clearPersistenceState());
  if (pathname === paths.BANK_TRANSFER_RESULT) {
    return;
  }

  yield put(pmStartAction());

  const paymentId = yield select(paymentIdSelector);

  const { result, json } = yield api.paymentMethods.getMethods({ paymentId });

  if (result.ok) {
    const preview = yield select(previewSelector);

    json.paymentMethods = onlyReleasedMethods(preview)(json.paymentMethods);

    yield put(
      pmSuccessAction({
        ...json,
        paymentMethods: onlyReleasedMethods(preview)(json.paymentMethods),
      }),
    );
  } else {
    const errorAction = pmErrorAction({
      ...json,
      status: result.status,
    });

    yield put(errorAction);
    yield paymentFlow.handlePaymentError(errorAction);
  }
}

function* removeTokenisedPaymentMethod({ api }, { payload: { action, tokenId } }) {
  const paymentId = yield select(paymentIdSelector);
  yield put(ccTokenisedPaymentMethodRemoveStartAction({ paymentId, tokenId }));

  const { result, json } = action
    ? yield api.shared.apiAction(action)
    : yield api.paymentMethods.removeTokenisedPaymentMethod({
        paymentId,
        tokenId,
      });

  if (result.ok) {
    yield put(
      ccTokenisedPaymentMethodRemoveSuccessAction({
        ...json,
        paymentId,
        tokenId,
      }),
    );

    yield put(pmRequestAction());
  } else {
    yield put(
      ccTokenisedPaymentMethodRemoveErrorAction({
        ...result,
        paymentId,
        tokenId,
      }),
    );
  }
}

/**
 * Report to New Relic if the card type is unrecognised by creditCardType, or is explicitly described as "unknown"
 */
function* reportUnrecognisedCardType(dependencies, { payload: { paymentMethods } }) {
  const paymentId = yield select(paymentIdSelector);
  paymentMethods.forEach(({ metaData, methodType, tokenized }) => {
    const cardType = (metaData && metaData.cardType) || '';
    if (tokenized && methodType === 'CARD') {
      const cardTypeInfo = creditCardType.getTypeInfo(cardType.toLowerCase());
      if (window.newrelic && (!cardTypeInfo || cardType.toLowerCase() === 'unknown')) {
        window.newrelic.addPageAction('currentMethodMetaData: Unrecognized Card Type', {
          cardType,
          paymentId,
        });
      }
    }
  });
}

const makeMainSaga = ({ behavior, ...params }) =>
  function* paymentMethodsSaga() {
    const { analytics, paymentFlow } = params;
    yield combineLatest([applicationInitializedAction, pmRequestAction], behavior.getMethods, params);

    yield takeLatest(ccTokenisedPaymentMethodRemoveConfirmAction, behavior.removeTokenisedPaymentMethod, params);

    yield takeLatest(pmSuccessAction, behavior.reportUnrecognisedCardType, params);

    yield takeLatest(psSetMethodAction, paymentFlow.redirect);

    yield takeLatest(psSetMethodAction, analytics.trackPaymentMethodSelected);
  };

export const behaviorForTesting = {
  getMethods,
  removeTokenisedPaymentMethod,
  makeMainSaga,
};

export default makeMainSaga({
  analytics: {
    ...sharedAnalyticsService,
    ...paymentMethodsAnalyticsService,
  },
  api: concreteApi,
  behavior: {
    getMethods,
    removeTokenisedPaymentMethod,
    reportUnrecognisedCardType,
  },
  paymentFlow: paymentFlowService,
});
