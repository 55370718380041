import Ninja from '../helpers/analytics.ninja';
import { frontEndAttemptID } from '../selectors/analytics';
import { integrator, paymentId, platform } from '../selectors/global';
import { getState } from '../store';

export const MethodTypeEnum = {
  BANK_TRANSFER: 'bank_transfer',
  INSTANT_BANK_TRANSFER: 'instant_bank_transfer',
  SMS: 'sms',
  GOOGLE_PAY: 'google_pay',
};

export const EventNameEnum = {
  METHOD_DISPLAYED: 'method_displayed', //when method is displayed to the user (Google pay button for example)
  METHOD_LINK_SELECTED: 'method_link_selected', // when user clicks on the about olx info, bottom of the form
  METHOD_PAGE_FILLED: 'method_page_filled', // when user fills the form. "implementation similar to card form"
  METHOD_PAGE_VIEWED: 'method_page_viewed', // when user lands on the PSE method/bank selection page
  PAYMENT_EXECUTION: 'payment_execution', // when the transaction is complete(successfully or unsuccessfully)
  PAYMENT_SELECTION_METHOD_SELECTED: 'payment_selection_method_selected', //  User clicks on the PSE tile on the payment selection page
  PAYMENT_SUBMITTED: 'payment_submitted', // when the user clicks on the pay button
  WRONG_PIN: 'wrong_pin',
  IFRAME_LOADED: 'iframe_loaded',
};

export default sendEvent.bind(null, 'e');
export const trackPageView = sendEvent.bind(null, 'p');

// Private implementation

function sendEvent(type, eventName, properties) {
  const url = Ninja.getUrl(type, eventName, {
    ...getHeadProperties(properties),
    ...properties,
  });

  return Ninja.trackWithImage(url);
}

function getHeadProperties({ back_end_attempt_id } = {}) {
  const state = getState();

  return {
    device_type: platform(state).toLowerCase(),
    encrypted_payment_id: paymentId(state),
    front_end_attempt_id: back_end_attempt_id || frontEndAttemptID(state),
    integrator: integrator(state).toLowerCase(),
  };
}
