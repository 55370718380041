// @flow
import { getOr } from 'lodash/fp';

import * as paths from '../pathConstants';
import type { PaymentStatus } from '../types/PaymentStatus';

type PaymentStatusRedirectPaths = {
  [status: PaymentStatus]: string,
};

const paymentStatusRedirectPaths: PaymentStatusRedirectPaths = {
  WAITING_PSP_ACTION: paths.PAYMENT_SELECTION,
  default: paths.INTERSTITIAL,
};

const determinePaymentStatusRedirection = (status: PaymentStatus) =>
  getOr(paymentStatusRedirectPaths.default, status, paymentStatusRedirectPaths);

export default determinePaymentStatusRedirection;
