import React from 'react';

import styled from '@emotion/styled';
import { padding, margin } from 'styled-system';

import { apps, commonIcons } from './icons';

export default ({ app, src, id = '', size, width, height, ...rest }: any) => {
  const store = apps[app] || commonIcons;
  const Ref = store[id.toUpperCase().replace(/-/g, '_')] || src;

  if (!Ref) {
    return null;
  }

  size = parseInt(size, 10) || null;
  width = parseInt(width, 10) || size;
  height = parseInt(height, 10) || size;

  return typeof Ref === 'string' ? (
    <ImgIcon src={Ref} alt={id} size={size} width={width} height={height} {...rest} />
  ) : (
    <SVGIcon Component={Ref} size={size} width={width} height={height} {...rest} />
  );
};

function ImgIcon({ src, alt, ...rest }) {
  return (
    <Figure {...rest}>
      <img src={src} alt={alt} />
    </Figure>
  );
}

function SVGIcon({ Component, size, width, height, ...rest }) {
  // const ref = useRef();

  // useEffect(() => {
  //   const current = ref.current.firstChild;
  //   if (current && current.getBBox) {
  //     const { x, y, width, height } = current.getBBox();
  //     current.setAttribute("viewBox", `${x} ${y}  ${width} ${height}`);
  //   }
  // }, []);

  return (
    <Figure height={height} width={width} {...rest}>
      <Component size={size} height={height} width={width} />
    </Figure>
  );
}

const Figure = styled.figure`
  height: ${({ height }) => (height ? `${height}px` : 'inherit')};
  width: ${({ width }) => (width ? `${width}px` : 'inherit')};
  ${padding};
  ${margin};
  display: flex;
  align-items: center;
  justify-content: center;

  > svg,
  > img {
    color: ${({ color, theme }) => theme.colors[color || 'background-brand-primary']};
    max-height: 100%;
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    object-fit: contain;
    max-width: 100%;
    width: ${({ height }) => (height ? 'auto' : '100%')};
  }
`;
