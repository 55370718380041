import React from 'react';

import styled from '@emotion/styled';
import DotsLoader from '@olxui/core-v10/dist/core/Loading/DotsLoader';
import { Paragraph4 } from '@olxui/core-v10/dist/core/Typography';

import { getMessage } from '../../helpers/lexemes';
import Modal from '../Modal';

export const ProgressLoader = ({ isOpen, children }: any) => (
  <Modal isOpen={isOpen} showCloseIcon={false} title={getMessage('App.form.card.confirm.title')}>
    <ModalBody>
      <DotsLoader />
      {children || <Paragraph4>{getMessage('App.form.card.confirm.paragraph')}</Paragraph4>}
    </ModalBody>
  </Modal>
);

export default ProgressLoader;

const ModalBody = styled.div`
  text-align: center;

  > div {
    padding: 32px 16px;
  }
`;
