import { responseInterceptors } from './registeredInterceptors';

const getInterceptor = (res, payload, interceptors) => interceptors.filter((i) => i.predicate(res, payload));

export const interceptResponse = (res, payload, url, method, options) => {
  const interceptors = getInterceptor(res, payload, responseInterceptors);
  if (!interceptors) {
    return payload;
  }
  return interceptors.reduce((pl, i) => {
    // null is used as a terminator so no other interceptor will work
    // example: when a sideEffect redirects
    if (pl === null) {
      return null;
    }
    return i.sideEffect(pl, res && res.clone ? res.clone() : res, url, method, options);
  }, payload);
};
