import { useEffect, useState } from 'react';

import { FormControl } from '@olxui/core';
import { Banner, MessageStatus } from '@olxui/core/dist/core/Banner/Banner';
import ButtonPrimaryDark from '@olxui/core/dist/core/Button/ButtonPrimaryDark';

import { getMessage } from '../../../../helpers/lexemes';
import { CountdownTimer } from '../CountdownTimer';
import { StyledForm } from './styled';
import { VpaOptionProps } from './types';

const defaultTime = 300;
const intervalTime = 2000;

export const VpaOption = ({
  price,
  hasError,
  segmentOption,
  isRejected,
  onGetStatus: onStatusRequest,
  onExecute,
}: VpaOptionProps) => {
  const [vpaValue, setVpaValue] = useState('');
  const [isTimeUp, setTimeUp] = useState(false);
  const [hasInputError, setInputError] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (hasError || !isFormSubmitted) return;

    const interval = setInterval(onStatusRequest, intervalTime);

    return () => clearInterval(interval);
  }, [onStatusRequest, hasError, isFormSubmitted]);

  const handleInputchange = (vpa: string) => {
    setVpaValue(vpa);
    setInputError(false);
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!vpaValue.length) {
      setInputError(true);
      return;
    }

    setFormSubmitted(true);
    onExecute(segmentOption, vpaValue);
  };

  const handleTimeIsUp = () => {
    setFormSubmitted(false);
    setTimeUp(true);
  };

  if (!hasError && !isRejected && isFormSubmitted) {
    return (
      <CountdownTimer
        timeInSeconds={defaultTime}
        activeMessage={getMessage('UPI.vpa.progressMessage')}
        secondsText={getMessage('Global.seconds')}
        minutesText={getMessage('Global.minutes')}
        onFinished={handleTimeIsUp}
      />
    );
  }

  return (
    <StyledForm onSubmit={handleFormSubmit}>
      {isTimeUp && (
        <Banner type={MessageStatus.WARNING} minHeight="unset">
          {getMessage(`UPI.vpa.timesup`)}
        </Banner>
      )}

      {isRejected && (
        <Banner type={MessageStatus.ERROR} minHeight="unset">
          {getMessage(`UPI.vpa.paymentRejected`)}
        </Banner>
      )}

      <FormControl
        label="UPI ID / VPA"
        error={hasInputError ? getMessage('UPI.vpa.form.upiId.error') : ''}
        hint="E.g. abc@upi"
        inputProps={{
          onChange: handleInputchange,
        }}
      />

      <ButtonPrimaryDark type="submit">
        {getMessage('Global.pay', {
          value: price,
        })}
      </ButtonPrimaryDark>

      {hasError && (
        <Banner type={MessageStatus.ERROR} minHeight="unset">
          {getMessage(`UPI.vpa.errorMessage`)}
        </Banner>
      )}
    </StyledForm>
  );
};
