import Axios from 'axios';
import { configure } from 'axios-hooks';

import getAppId from '../../helpers/getAppId';

const orchestrationHostList = {
  // Europe
  pl: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
  ua: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
  ro: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
  advertisementpl: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
  autohistory: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
  za: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,

  // PNM
  id: process.env.REACT_APP_PL_PNM_ORCHESTRATION_URL,
  in: process.env.REACT_APP_PL_PNM_ORCHESTRATION_URL,
  tr: process.env.REACT_APP_PL_PNM_ORCHESTRATION_URL,

  // Latam
  co: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,
  ar: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,
  pe: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,
  ec: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,
  autosmx: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,
  autoscl: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,

  default: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
};

const getOrchestrationHost = (appId = getAppId()) => {
  return orchestrationHostList[appId] ?? orchestrationHostList.default;
};

type configureBaseRequestProps = { token?: string };

export const configureBaseRequest = ({ token }: configureBaseRequestProps) => {
  const axios = Axios.create({
    baseURL: getOrchestrationHost(),
  });

  axios.interceptors.request.use(function (config) {
    if (config.url && config.url?.search(/execute/g) > 0 && config.headers) {
      config.headers['Browser-Width'] = screen.width.toString();
      config.headers['Browser-Height'] = screen.height.toString();
      config.headers['Browser-Color-Depth'] = screen.colorDepth.toString();
      config.headers['Browser-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      config.headers['X-Client-User-Agent'] = navigator.userAgent;
    }

    if (config.headers && token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.data?.message === 'SESSION_EXPIRED') {
        window.location = error.response.data.action.uri;
      }

      return Promise.reject(error);
    },
  );

  configure({ axios });
};
