// @flow
import { flow, get } from 'lodash/fp';
import { createSelector } from 'reselect';

import parseInitParams from '../helpers/parseInitParams';
import { search as searchSelector } from './routing';

const main = get('global');

const initParams = createSelector(searchSelector, flow(parseInitParams, get('result')));

export const varsParams = createSelector(searchSelector, flow(parseInitParams, get('vars')));

export const locale = createSelector(initParams, get('locale'));
export const platform = createSelector(initParams, get('platform'));
export const integrator = createSelector(initParams, get('integrator'));

export const paymentId = createSelector(initParams, get('paymentId'));
export const paymentFlavor = createSelector(initParams, get('paymentFlavor'));
export const customerActionUrl = createSelector(initParams, get('customerActionUrl'));

export default main;
