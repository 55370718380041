// @flow

import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

import { isInPaymentResultPath as isInPaymentResultPathSelector } from './routing';

const creditCard = get('creditCard');
const fullPageLoader = get('fullPageLoader');
const paymentStatus = get('paymentStatus');

export const showFullPageLoader = createSelector(
  fullPageLoader,
  isInPaymentResultPathSelector,
  (result, isInPaymentResultPath) => isInPaymentResultPath || result > 0,
);

export const showProgressLoader = createSelector(
  creditCard,
  paymentStatus,
  ({ confirming }, { confirmingTokenized }) => {
    return confirming || confirmingTokenized;
  },
);
