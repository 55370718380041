// @flow

import { omit } from 'lodash/fp';
import { delay } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as concreteApi from '../../api';
import { orchestrationHost } from '../../api/shared';
import { paymentId as paymentIdSelector } from '../../selectors/global';
import { preselectedMethodFlavor as isPreselectedSelector } from '../../selectors/paymentMethods';
import { statusRequestConfig as statusRequestConfigSelector } from '../../selectors/paymentStatus';
import { queryParams as queryParamsSelector } from '../../selectors/routing';
import {
  psAuthoriseFormAction,
  psContinueRequestStatusAction,
  psRequestStatusAction,
  psStartAction,
  psSuccessAction,
  psTimeoutAction,
  psErrorAction,
} from '../actions/paymentStatus';
import * as creditCardAnalyticsService from './analytics/creditCardAnalyticsService';
import * as sharedAnalyticsService from './analytics/shared';
import * as paymentFlowService from './paymentFlowService';

const POLL_INTERVAL = 1000;

export const POLL_ATTEMPTS = 15;

function* interpretAction({ analytics, paymentFlow }, action) {
  let shouldContinue = true;

  switch (action.type) {
    case 'REDIRECT_FORM':
      const redirectAction = psAuthoriseFormAction({
        requestUrl: action.url || action.uri,
        request: action.params,
      });

      yield analytics.trackCCRedirectAction(redirectAction);
      yield put(redirectAction);
      yield paymentFlow.redirectViaFormSubmit(redirectAction);
      shouldContinue = false;
      break;
    default:
  }

  return { shouldContinue };
}

function* interpretErrorMessage(message, errorAction, paymentFlow) {
  switch (message) {
    case 'RE_ENTER_CARD_DATA': {
      yield paymentFlow.redirectToCreditCard(errorAction);
      return;
    }

    default: {
      const isSelectedMethodFlavor = yield select(isPreselectedSelector);
      if (isSelectedMethodFlavor) {
        yield paymentFlow.redirectToCreditCard(errorAction);
        return;
      }
      yield paymentFlow.redirectToPaymentSelection(errorAction);
      return;
    }
  }
}

function* interpretStatus({ analytics, paymentFlow }, responseBody) {
  const {
    action,
    completionUrl,
    status,
    encryptedAttemptId,
    methodType,
    applied3DSecure,
    tokenised = false,
    message,
  } = responseBody;

  let shouldContinue = true;

  switch (status) {
    case 'REJECTED':
    case 'ERROR':
      const errorAction = psErrorAction({
        ...responseBody,
        methodType,
        tokenised,
      });

      yield analytics.trackPaymentFailure(errorAction);
      yield put(errorAction);
      yield interpretErrorMessage(message, errorAction, paymentFlow);
      shouldContinue = false;
      break;

    case 'CAPTURED':
      {
        const successAction = psSuccessAction({
          body: {
            redirectUrl: completionUrl || (action || {}).url || (action || {}).uri,
            encryptedAttemptId,
            applied3DSecure,
            methodType,
            tokenised,
          },
        });

        yield analytics.trackPaymentSuccess(successAction);
        yield put(successAction);
        yield paymentFlow.handlePaymentFlowFinished(successAction);
        shouldContinue = false;
      }

      break;
    default:
  }

  return { shouldContinue };
}

function* handleFailureResponse({
  analytics,
  paymentFlow,
  statusResponseBody,
  statusRequestConfig,
  remainingPollAttempts,
}) {
  if (statusRequestConfig && remainingPollAttempts > 0) {
    // Keep polling for status
    yield put(
      psContinueRequestStatusAction({
        remainingPollAttempts: remainingPollAttempts - 1,
      }),
    );
  } else if (remainingPollAttempts === 0) {
    // TODO Missing data required for analytics, discuss with BE
    const timeoutAction = psTimeoutAction({
      // encryptedAttemptId,
      // methodType: trackingMethodType
    });
    yield analytics.trackPaymentTimeout(timeoutAction);
    yield put(timeoutAction);
    yield paymentFlow.redirectOnStatusTimeout();
  } else {
    yield put(psErrorAction(statusResponseBody));
  }
}

function* handleSuccessResponse({ analytics, paymentFlow, statusResponseBody, remainingPollAttempts }) {
  const actionResult = yield interpretAction({ analytics, paymentFlow }, statusResponseBody.action || {});

  if (!actionResult.shouldContinue) {
    return;
  }

  const statusResult = yield interpretStatus({ analytics, paymentFlow }, statusResponseBody);

  if (!statusResult.shouldContinue) {
    return;
  }

  if (remainingPollAttempts > 0) {
    // Keep polling for status
    yield put(
      psContinueRequestStatusAction({
        remainingPollAttempts: remainingPollAttempts - 1,
      }),
    );
  } else {
    const { encryptedAttemptId, methodType, status, tokenised } = statusResponseBody;

    const timeoutAction = psTimeoutAction({
      encryptedAttemptId,
      methodType,
      tokenised,
      status,
    });

    yield analytics.trackPaymentTimeout(timeoutAction);
    yield put(timeoutAction);
    yield paymentFlow.redirectOnStatusTimeout(timeoutAction);
  }
}

function* getStatus({ analytics, api, paymentFlow, remainingPollAttempts = POLL_ATTEMPTS }) {
  const paymentId = yield select(paymentIdSelector);
  yield put(psStartAction());

  try {
    const queryParams = yield select(queryParamsSelector);
    const { attemptId, ...integratorQueryParams } = omit(['init'], queryParams);
    const statusRequestConfig = yield select(statusRequestConfigSelector);

    const statusParams = {
      paymentId,
      attemptId,
      queryString: integratorQueryParams,
    };

    if (statusRequestConfig && statusRequestConfig.uri && statusRequestConfig.uri.indexOf('http') < 0) {
      statusRequestConfig.uri = orchestrationHost() + statusRequestConfig.uri;
      statusRequestConfig.url = orchestrationHost() + statusRequestConfig.url;
    }

    const { result, json } = yield api.paymentStatus.getStatus(statusParams, {
      action: statusRequestConfig,
    });

    if (result.ok) {
      yield handleSuccessResponse({
        analytics,
        paymentFlow,
        statusResponseBody: json,
        remainingPollAttempts,
      });
    } else {
      yield handleFailureResponse({
        analytics,
        paymentFlow,
        statusResponseBody: json,
        statusRequestConfig,
        remainingPollAttempts,
      });
    }
  } catch (error) {
    const errorAction = psErrorAction(error);
    yield analytics.trackPaymentFailure(errorAction);
    yield put(errorAction);
    throw error;
  }
}

function* pollForStatus(params, { payload: { remainingPollAttempts } }) {
  yield call(delay, POLL_INTERVAL);
  yield getStatus({ ...params, remainingPollAttempts });
}

const makeMainSaga = ({ behavior, ...params }) =>
  function* paymentStatusSaga() {
    yield takeLatest(psRequestStatusAction, behavior.getStatus, params);
    yield takeLatest(psContinueRequestStatusAction, behavior.pollForStatus, params);
  };

export default makeMainSaga({
  analytics: {
    ...sharedAnalyticsService,
    ...creditCardAnalyticsService,
  },
  api: concreteApi,
  behavior: {
    getStatus,
    pollForStatus,
  },
  paymentFlow: paymentFlowService,
});

export const behaviorForTesting = {
  getStatus,
  // NOTE to test if the main saga reacts to required actions.
  makeMainSaga,
};
