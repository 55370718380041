// @flow

import type { EventTrackingArgs, TrackingStrategy } from '../../../types/Tracker';

function* trackPage({ eventName, params }: EventTrackingArgs) {
  if (window.newrelic) {
    yield window.newrelic.addPageAction(eventName, params);
  }
}

function* trackEvent({ eventName, params }: EventTrackingArgs) {
  if (window.newrelic) {
    yield window.newrelic.addPageAction(eventName, params);
  }
}

const newRelicTrackingStrategy: TrackingStrategy = {
  trackPage,
  trackEvent,
};

export default newRelicTrackingStrategy;
