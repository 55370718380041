// @flow

import { handleActions } from 'redux-actions';

import { defaultLocale } from '../../helpers/defaultLocale';
import type { PaymentFlavor } from '../../types/PaymentFlavor';
import { setLoading, stopLoading, clearStateAction, setEnvVarsAction } from '../actions/global';

type State = {
  loading: boolean,
  locale: string,
  integrator: string,
  paymentId?: string,
  paymentFlavor?: PaymentFlavor,
};

export const defaultState: State = {
  loading: false,
  locale: defaultLocale,
  integrator: 'olx-classic',
  paymentId: undefined,
  paymentFlavor: 'WALLET',
};

export default handleActions(
  {
    [clearStateAction]: (state) => ({
      ...defaultState,
      locale: state.locale,
    }),
    [setLoading]: (state) => ({
      ...state,
      loading: true,
    }),
    [stopLoading]: (state) => ({
      ...state,
      loading: false,
    }),
    [setEnvVarsAction]: (state, action) => ({
      ...state,
      env: action.payload,
    }),
    SET_GLOBALS: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  defaultState,
);
