import React from 'react';

import template from 'string-template';

import currency from './currency-formatter';

if (!global?.__DEV__) {
  global.__DEV__ = false;
}

const data = { messages: {}, formats: {}, links: {} };
let Lexeme = null;

export const addLocaleData = (config) => {
  const { messages, formats, links } = config;
  Object.assign(data, { messages, formats, links });
};

export function getLink(id = '') {
  const link = data.links[id];
  __DEV__ && !link && console.info(`[LINK]: The id "${id}" was not found.`);

  return link || '#';
}

export function getMessage(id = '', values) {
  if (__DEV__ && Lexeme) {
    return <Lexeme id={id} />;
  }

  if (values) {
    return formatMessage(id, values);
  }

  return data.messages[id] || mask(id);
}

const mask = (id) => {
  __DEV__ && console.info(`[LEXEME]: The id "${id}" was not found.`);
  const cut = Math.floor(Math.random() * 16) + 8;

  return id.replace(/\./g, ' ').replace(/\S/gi, '░').substring(0, cut);
};

export function formatCurrency(value, overrideConfig) {
  const config = overrideConfig
    ? {
        ...data.formats.currencyConfig,
        ...overrideConfig,
      }
    : data.formats.currencyConfig;

  return currency(value, config);
}

function formatMessage(id, values) {
  return template(getMessage(id), values);
}

if (__DEV__) {
  const parseParams = (params) =>
    Object.entries(params)
      .map(([key, value]) => value && `${key}=${value}`)
      .filter(Boolean)
      .join('&');

  const post = async (url, { headers, body, ...rest } = {}) => {
    const { result, response } = await window
      .fetch(url, {
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...headers,
        },
        body: parseParams({
          api_token: 'ADD_API_TOKEN',
          id: '324505',
          ...body,
        }),
        ...rest,
      })
      .then((res) => res.json());

    return result ? Object.values(result)[0] : response;
  };

  (async () => {
    const getItem = (id) => localStorage.getItem(id);
    const removeItem = (id) => localStorage.removeItem(id);
    const setItem = (id, value) => localStorage.setItem(id, value);
    const ALLOW_TRANSLATE_TOOL = Boolean(getItem('__translate__'));
    const ALLOW_SHOW_KEYS = Boolean(getItem('__show_keys__'));
    const changes = {};
    const api = 'https://api.poeditor.com/v2/';
    const updateLangues = post.bind(null, `${api}languages/update`);

    window.dev = {
      ...window.dev,

      getLexemes() {
        return {
          ...data.messages,
          ...changes,
        };
      },
      translate() {
        setItem('__translate__', 1);
        window.location.reload();
      },
      translateStop() {
        removeItem('__translate__');
        window.location.reload();
      },
      save() {
        const language = prompt('What locale are you saving?');
        const diff = {};

        for (const key in changes) {
          const value = changes[key];
          if (value !== data.messages[key]) {
            diff[key] = value;
          }
        }
        const diffList = Object.entries(diff);

        return updateLangues({
          body: {
            language,
            data: JSON.stringify(
              diffList.map(([term, content]) => ({
                term,
                translation: { content },
              })),
            ),
          },
        });
      },
      showKeys() {
        setItem('__show_keys__', 1);
        window.location.reload();
      },
      hideKeys() {
        removeItem('__show_keys__');
        window.location.reload();
      },
    };
  })();
}
