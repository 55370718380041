// @flow

import Ninja from '../../../helpers/analytics.ninja';
import type { EventTrackingArgs, TrackingStrategy } from '../../../types/Tracker';

function* trackPage({ eventName, params }: EventTrackingArgs) {
  yield Ninja.trackWithImage(Ninja.getUrl('p', eventName, params));
}

function* trackEvent({ eventName, params }: EventTrackingArgs) {
  yield Ninja.trackWithImage(Ninja.getUrl('e', eventName, params));
}

const ninjaTrackingStrategy: TrackingStrategy = {
  trackPage,
  trackEvent,
};

export default ninjaTrackingStrategy;
