

import React from 'react';

import styled from '@emotion/styled';
import { noop, omit } from 'lodash/fp';
import querystring from 'query-string';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';

import populateParams from '../helpers/populateParams';
import { analyticsTrackLinkAction } from '../redux/actions/analytics.ninja';

const isExternalUrl = (url) => url && (url.indexOf('http://') === 0 || url.indexOf('https://') === 0);

const getRelevantLinkProps = omit([
  'staticContext',
  'trackingParams',
  'trackLink',
  'match',
  'history',
  'location',
  'textLink',
]);

type Props = {
  to: string,
  qs: {
    init?: string,
    token?: string,
    [key: string]: string,
  },
  children: React.Node,
  textLink?: boolean,
  match: {
    params: { [key: string]: string },
  },
};

const getSearchString = (properties: string[], qs: { [key: string]: string } = {}): string => {
  return querystring.stringify(
    properties.reduce((obj, p) => {
      if (qs[p] !== undefined) {
        obj[p] = qs[p];
      }
      return obj;
    }, {}),
  );
};

const getDestination = (to: string, qs: { [key: string]: string }, properties: string[]): string => {
  const search = getSearchString(properties, qs);
  return `${to}${search.length ? `?${search}` : ''}`;
};

export const Link = (props: Props) => {
  const { to, qs = {}, children, ...remainingProps } = props;
  const relevantLinkProps = getRelevantLinkProps(remainingProps);
  return isExternalUrl(to) ? (
    <a href={to} {...relevantLinkProps}>
      {children}
    </a>
  ) : (
    <RouterLink
      to={populateParams(getDestination(to, qs, ['init', 'token']), props.match.params)}
      {...relevantLinkProps}
    >
      {children}
    </RouterLink>
  );
};

// Styled components

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors['text-global-secondary']};
`;

export const withHandlersEnhancer = withHandlers({
  onClick:
    ({ trackLink, trackingParams, onClick = noop }) =>
    (event) => {
      if (trackingParams) {
        trackLink(trackingParams);
      }

      onClick(event);
    },
});

export default compose(
  connect(
    (state) => ({
      qs: state.global.env,
    }),
    {
      trackLink: analyticsTrackLinkAction,
    },
  ),
  withHandlersEnhancer,
  withRouter,
)(StyledLink);
