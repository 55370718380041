import type { ApiV2Response } from '../../types/ApiV2Response';
import type { InterceptorType } from './InterceptorType';

export const unauthorizedInterceptor: InterceptorType = {
  predicate: (res: Response<ApiV2Response>, payload: ApiV2Response) =>
    !!(
      res &&
      payload &&
      (res.status === 401 || res.status === 498) &&
      payload.action &&
      payload.action.httpMethod === 'GET' &&
      payload.action.uri
    ),
  sideEffect: (payload: ApiV2Response) => {
    switch (payload.version) {
      case 'V1':
        break;
      case 'V2':
      case 'V2.1': {
        if (payload.action.type === 'REDIRECT') {
          window.location.assign(payload.action.uri);
          return null;
        }

        break;
      }
      default: {
        break;
      }
    }

    return payload;
  },
};
