import styled from '@emotion/styled';

export const StyledForm = styled('form')`
  width: 100%;
  margin-bottom: 20px;

  div > div {
    width: 100%;
  }

  & > * {
    width: 100%;
    margin-bottom: 20px !important;
  }
`;
