// @flow

import { get } from 'lodash/fp';
import { createSelector, createStructuredSelector } from 'reselect';

import { frontEndAttemptID } from './analytics';
import { error } from './error';
import { paymentId } from './global';

export const paymentMethods = get('paymentMethods');
const generic = get('generic');

export const methods = createSelector(paymentMethods, get('methods'));
export const payment = createSelector(paymentMethods, get('payment'));

const creditCard = get('creditCard');
const ccContext = createSelector(creditCard, get('paymentInfo.context'));

// In the case of credit card init-by-convention, the cancelUrl is stored in the credit card context
const pmCancelUrl = createSelector(paymentMethods, get('cancelUrl'));
const ccCancelUrl = createSelector(creditCard, get('paymentInfo.context.cancelUrl'));
const pmCustomerActionUrl = createSelector(paymentMethods, get('customerActionUrl'));
const ccCustomerActionUrl = createSelector(creditCard, get('paymentInfo.context.customerActionUrl'));

const cancelUrlSelector = createSelector(pmCancelUrl, ccCancelUrl, (pmUrl, ccUrl) => pmUrl || ccUrl);

const customerActionUrlSelector = createSelector(
  pmCustomerActionUrl,
  ccCustomerActionUrl,
  (pmUrl, ccUrl) => pmUrl || ccUrl,
);

const backUrlSelector = createSelector(
  customerActionUrlSelector,
  cancelUrlSelector,
  (customerActionUrl, cancelUrl) => customerActionUrl || cancelUrl,
);

const loading = createSelector(paymentMethods, get('loading'));

// If Payment Methods page is loaded, the description is stored in "paymentMethods.description"
// If Generic Page is loaded, the description is stored in "generic.description"
export const description = createSelector([paymentMethods, generic, ccContext], (pm = {}, gen = {}, ccc = {}) => {
  return pm.description || gen.description || ccc.description;
});

// In the case of credit card init-by-convention, the flavors are stored in the credit card context
const flavorsFromPaymentMethods = createSelector(paymentMethods, get('flavors'));
const flavorsFromCreditCard = createSelector(ccContext, (context) => {
  return (context ? context.flavors : []) || [];
});
export const flavors = createSelector(flavorsFromPaymentMethods, flavorsFromCreditCard, (pmf = [], ccf = []) => {
  if (pmf.length > ccf.length) {
    return pmf;
  }
  return ccf;
});

export const preselectedMethodFlavor = createSelector(
  flavors,
  (flavor) => flavor && flavor.find((obj) => obj === 'PRE_SELECTED_METHOD_CARD'),
);

export default createStructuredSelector({
  paymentId,
  methods,
  payment,
  description,
  cancelUrl: cancelUrlSelector,
  backUrl: backUrlSelector,
  error,
  frontEndAttemptID,
  flavors,
  loading,
});
