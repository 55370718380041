import { theme } from '@olxui/core/dist/theme/CoreTheme/coreTheme';

import mq from './mediaQueries';
import { AppTheme } from './types';

const colors = {
  primary: '#FF3F55',
  secondary: '#949494',
  tertiary: '#2C2C2C',
  grey: '#E0E0E0',
  white: '#ffffff',
};

export const letgo: AppTheme = {
  ...theme,
  components: {
    BackButton: {
      '> svg': {
        color: colors.secondary,
      },
    },
    BasketDetails: {
      [mq.gt('phablet')]: {
        border: `1px solid ${colors.grey}`,
        'h6:last-child': {
          fontWeight: 'bold !important',
        },
      },
    },
    Body: {
      [mq.gt('phablet')]: {
        backgroundColor: colors['text-global-inverse'],
      },
    },
    ListItem: {
      [mq.gt('phablet')]: {
        border: `1px solid ${colors.grey} !important`,
      },
    },
    HeaderTop: {
      [mq.gt('phablet')]: {
        backgroundColor: `${colors.primary} !important`,
      },
    },
  },
  staticConfig: {
    favIcon: '/icons/favicon-letgo.ico',
  },
};
