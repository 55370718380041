import { combineActions, handleActions } from 'redux-actions';

import { ccStartAction, ccErrorAction, ccSuccessAction, ccRedirectAction } from '../actions/creditCard';
import {
  genActionAPIFailure,
  genActionAPIStart,
  genActionAPISuccess,
  genInitializeFailure,
  genInitializeStart,
  genInitializeSuccess,
  genInitByConvention,
} from '../actions/generic';
import {
  ibtSelectBankAction,
  ibtSelectBankErrorAction,
  ibtOfflineBankSelectAttemptAction,
  ibtStartV2,
} from '../actions/instantBankTransfer';
import { loadableStart, loadableFinish } from '../actions/loadable';
import { mbtStartAction, mbtSuccessAction, mbtErrorAction } from '../actions/manualBankTransfer';
import { otcStartAction } from '../actions/overTheCounter';
import { pmStartAction, pmErrorAction, pmSuccessAction } from '../actions/paymentMethods';
import { psRequestStatusAction, psErrorAction, psTimeoutAction } from '../actions/paymentStatus';
import { initializeAction, finalizeAction } from '../actions/v2';

const defaultState = 0;

export const increment = (state) => state + 1;
export const decrement = (state) => Math.max(0, state - 1);

const showLoaderWhen = [
  // Actions that should show the loader.
  ccStartAction,
  ibtSelectBankAction,
  pmStartAction,
  psRequestStatusAction,
  loadableStart,
  // In the case of redirections, we want the spinner until the redirection happens
  ccRedirectAction,
  otcStartAction,
  ibtStartV2,
  initializeAction,
  mbtStartAction,
  genInitializeStart,
  genActionAPIStart,
  genInitByConvention,
];

const hideLoaderWhen = [
  // Actions that should hide the loader.
  ccErrorAction,
  ccSuccessAction,
  ibtSelectBankErrorAction,
  ibtOfflineBankSelectAttemptAction,
  finalizeAction,
  pmErrorAction,
  pmSuccessAction,
  psTimeoutAction,
  psErrorAction,
  loadableFinish,
  mbtSuccessAction,
  mbtErrorAction,
  genInitializeSuccess,
  genInitializeFailure,
  genActionAPISuccess,
  genActionAPIFailure,
];

export default handleActions(
  {
    [combineActions(...showLoaderWhen)]: increment,
    [combineActions(...hideLoaderWhen)]: decrement,
  },
  defaultState,
);
