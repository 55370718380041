// @flow
import getAppId from '../helpers/getAppId';

const localesMap = {
  pl: 'pl',
  za: 'en',
  pk: 'en',
  ua: 'uk_UA',
  tr: 'tr',
};

export const defaultLocale = localesMap[getAppId()] || 'en';
