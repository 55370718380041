import { useEffect } from 'react';

import useAxios from 'axios-hooks';

import { useLoader } from '../../../../hooks';
import { UseUpiTransferExecuteReturn } from './types';

export const useUpiTransferExecute = (url: string): UseUpiTransferExecuteReturn => {
  const [{ data, loading, error }, request] = useAxios(
    {
      url,
      method: 'post',
    },
    { manual: true },
  );

  if (data?.action?.type == 'REDIRECT') {
    window.location = data.action.uri;
  }

  const { showLoading, hideLoading } = useLoader();

  useEffect(() => {
    loading ? showLoading() : hideLoading();
  }, [loading]);

  const handleExecuteRequest = (brandCode: string, virtualPaymentAddress?: string) => {
    const data = { brandCode };

    if (virtualPaymentAddress) {
      data['virtualPaymentAddress'] = virtualPaymentAddress;
    }

    request({ data });
  };

  return { data, error, handleExecuteRequest };
};
