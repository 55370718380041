// @flow

import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

import type { PlutusMessage } from '../types/PlutusMessages';
// import type { PlutusSmsErrorStatus } from "../types/SmsErrors";

const main = get('error');

const rawGlobalError = createSelector(main, get('global'));
const rawCardError = createSelector(main, get('card'));

const RETRY_PAYMENT_ERROR = 'Global.error.retryPayment';
const CHOOSE_ANOTHER_METHOD_ERROR = 'Global.error.chooseAnotherMethod';

const errorResourceIds: {
  [key: PlutusMessage /** | PlutusSmsErrorStatus */]: string,
} = {
  AUTH_ERROR: 'Global.error.somethingWrongChooseAnotherMethod',
  TIMED_OUT: RETRY_PAYMENT_ERROR,
  CANCELLED: RETRY_PAYMENT_ERROR,
  SYNTAX_ERROR: 'Global.error.somethingWrongChooseAnotherMethod',
  SERVER_ERROR: RETRY_PAYMENT_ERROR,
  INSUFFICIENT_FUNDS: CHOOSE_ANOTHER_METHOD_ERROR,
  NOT_SUPPORTED: CHOOSE_ANOTHER_METHOD_ERROR,
  PIN_ERROR: 'Global.error.chooseAnotherMethodOrPhoneNumber',
  CHARGE_ERROR: 'Global.error.retrySMS',
  CHARGE_LIMIT: 'Global.error.chooseAnotherMethodOrPhoneNumber',
  RETRY_METHOD: 'Global.error.retryMethod',
  TRY_OTHER_METHOD: CHOOSE_ANOTHER_METHOD_ERROR,
  RE_ENTER_CARD_DATA: 'Global.error.reEnterCardData',
  PENDING: 'Global.error.pending',
  UNKNOWN: 'Global.error.generic',
};

const getErrorResourceId = (err) => (err ? errorResourceIds[err] || `Global.error.${err}` : '');

export const error = createSelector(rawGlobalError, getErrorResourceId);

export const cardError = createSelector(rawCardError, (err) => {
  return getErrorResourceId(err);
});

export const hasError = createSelector(error, (err) => !!err);
