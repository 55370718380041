// @flow

import { flow, getOr, has, mapValues, merge, reduce as cappedReduce, noop } from 'lodash/fp';
import qs from 'query-string';

import getAppId from '../helpers/getAppId';
import getAuthToken from '../helpers/getAuthToken';
import * as nr from '../helpers/new-relic-wrapper';
import { previewHeaders as getPreviewHeaders, testCase as getTestCase } from '../selectors/previewMode';
import type { ApiResponse } from '../types/api';
import type { ApiCallOptions } from '../types/ApiCallOptions';
import type { ApiV2Action } from '../types/ApiV2Action';
import type { FetchOptions } from '../types/FetchOptions';
import { interceptResponse } from './interceptors';

const reduce = cappedReduce.convert({ cap: false });

const fillUrlTemplate = (urlTemplate: string, params: RequestParams) => {
  const safeParams = mapValues(encodeURIComponent)(params);
  return flow(
    reduce((url, value, name) => url.replace(new RegExp(`:${name}`, 'g'), value), urlTemplate),
    (url) => {
      if (has('queryString', params)) {
        return url + '?' + qs.stringify(params.queryString);
      }

      return url;
    },
  )(safeParams);
};

const shouldMockOrchestration = () =>
  process.env.REACT_APP_RUNTIME_ENVIRONMENT === 'development' &&
  /[?&]mockOrchestration=true(&|$)/.test(window.location.search);

const appIdsOrchestrationHostMap = {
  // Europe
  pl: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
  ua: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
  ro: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
  advertisementpl: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
  autohistory: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
  za: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,

  // PNM
  id: process.env.REACT_APP_PL_PNM_ORCHESTRATION_URL,
  in: process.env.REACT_APP_PL_PNM_ORCHESTRATION_URL,
  tr: process.env.REACT_APP_TR_ORCHESTRATION_URL,

  // Latam
  co: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,
  ar: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,
  pe: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,
  ec: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,
  autosmx: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,
  autoscl: process.env.REACT_APP_PL_LATAM_ORCHESTRATION_URL,

  default: process.env.REACT_APP_PL_EUROPE_ORCHESTRATION_URL,
};

export const orchestrationHost = (appId = getAppId()) => {
  if (shouldMockOrchestration()) {
    return process.env.REACT_APP_MOCK_ORCHESTRATION_URL;
  }

  return (
    appIdsOrchestrationHostMap[appId] ??
    appIdsOrchestrationHostMap.default ??
    process.env.REACT_APP_PL_ORCHESTRATION_URL ??
    ''
  );
};

type RequestParams = {
  queryString?: Object,
  [key: string]: string | number,
};

const createApiUriStrategies = {
  V1: (urlTemplate: string, params: RequestParams) =>
    encodeURI(`${orchestrationHost()}/payments${fillUrlTemplate(urlTemplate, params)}`),
  V2: (urlTemplate: string, params: RequestParams, options: ApiCallOptions) => {
    if (options.action && (options.action.uri || options.action.url)) {
      return encodeURI(fillUrlTemplate(options.action.uri || options.action.url));
    }

    throw new Error('Incorrect definition of a V2 API call, provide options.action.uri.');
  },
  'V2.1': (_, __, { actions }: ApiCallOptions) => {
    try {
      return encodeURI(fillUrlTemplate(actions.submit.uri));
    } catch (error) {
      throw new Error('Incorrect definition of a V2.1 API call, provide `actions.submit.uri`.');
    }
  },
};

const createApiUri = (urlTemplate: string, params: RequestParams, options: FetchOptions) => {
  const version = getOr('V1', 'version', options);
  const strategy = createApiUriStrategies[version];

  return strategy(urlTemplate, params, options);
};

const injectAppIdHeader = merge({ headers: { 'Plutus-App-Id': getAppId() } });

const injectAuthToken = (req) => {
  const token = getAuthToken();
  return token ? merge({ headers: { Authorization: `Bearer ${token}` } }, req) : req;
};

const injectPreviewHeaders = (req) => {
  const previewHeaders = getPreviewHeaders();
  return previewHeaders ? merge({ headers: { 'X-Plutus-Features': previewHeaders } }, req) : req;
};

export const injectTestCaseHeaders = (req) => {
  const testCaseHeaders = getTestCase();
  return testCaseHeaders ? merge({ headers: { 'X-Plutus-TestCase': testCaseHeaders } }, req) : req;
};

const injectContentTypeHeader = (req) => {
  const { method, action = {} } = req;
  if (action.contentType && (method === 'POST' || method === 'PUT')) {
    return merge({ headers: { 'Content-Type': action.contentType } }, req);
  }
  return req;
};

export const decorateFetchOptions = flow(
  injectAppIdHeader,
  injectAuthToken,
  injectPreviewHeaders,
  injectTestCaseHeaders,
  injectContentTypeHeader,
  // More decorators here...
);

const determineApiVersion = (apiCallOptions: ApiCallOptions) =>
  apiCallOptions.version || (apiCallOptions.action ? 'V2' : 'V1');

const getBackendEndpointToReplace = {
  production: 'https://api.plutus.olx.tools',
  staging: 'https://api-stg.plutus.olx.tools',
};

export const simpleFetch = async ({ url, method, options }: { url: string, method?: string, options?: any }) => {
  const opts = { ...options };
  if (method) {
    opts.method = method;
  }
  opts.credentials = 'include';

  let result: Response;
  let payload = null;
  let decoratedOptions = null;
  let logsOptions = null;
  try {
    // TODO: Remove this hack when backend is updated providing the non Akamai URLs

    if (url.startsWith(getBackendEndpointToReplace[process.env.REACT_APP_RUNTIME_ENVIRONMENT])) {
      url = url.replace(getBackendEndpointToReplace[process.env.REACT_APP_RUNTIME_ENVIRONMENT], orchestrationHost());
    }

    decoratedOptions = decorateFetchOptions(opts);

    const { Authorization, ...rest } = decoratedOptions.headers;
    logsOptions = rest;

    nr.addPageAction(nr.PAGE_ACTION_NAMES.API_START, {
      requestUrl: url,
      options: { ...decoratedOptions, headers: { ...logsOptions } },
    });

    result = await fetch(url, decoratedOptions);
    payload = await result.clone().json().catch(noop);
    payload = interceptResponse(result, payload, url, method, decoratedOptions);
  } catch (err) {
    nr.addPageAction(nr.PAGE_ACTION_NAMES.API, {
      status: nr.API_STATUS.FAIL,
      ...err,
      requestUrl: url,
      options: { ...decoratedOptions, headers: { ...logsOptions } },
      payload,
    });
    return {
      result: { ...result, url, ok: false, statusText: err.message },
      json: payload,
    };
  }
  return { result, json: payload };
};

// TODO fix flow
export const apiCall =
  (defaultMethod = 'GET') =>
  (urlTemplate: string) =>
    async function apiCall<TResult>(
      params: RequestParams,
      apiCallOptions: ApiCallOptions = {},
    ): Promise<ApiResponse<TResult>> {
      const method = apiCallOptions.httpMethod || defaultMethod;
      const url = createApiUri(urlTemplate, params, {
        ...apiCallOptions,
        version: determineApiVersion(apiCallOptions),
      });

      return await simpleFetch({
        url,
        method,
        options: apiCallOptions,
      });
    };

export const get = apiCall();
export const _delete = apiCall('DELETE');
export const put = apiCall('PUT');
export const post = apiCall('POST');

export const behaviorForTesting = {
  fillUrlTemplate,
};

export const apiAction = (
  { uri, params, httpMethod, payload, contentType }: ApiV2Action,
  { body, additionalHeaders = {}, ...rest }: any = {},
) =>
  simpleFetch({
    method: httpMethod,
    options: {
      body: JSON.stringify({ ...params, ...payload, ...body }),
      headers: merge(additionalHeaders, {
        Accept: 'application/json',
        'Content-Type': contentType || 'application/json',
      }),
      ...rest,
    },
    url: uri,
  });
