// @flow

import type { PropertiesScrubType, PropertyScrubType } from './types';

export const _propertyScrub: PropertiesScrubType = {};

export const setPropertyScrub: (string, PropertyScrubType) => void = (propName, predicate) => {
  _propertyScrub[propName] = predicate;
};
export const setPropertiesScrub: (PropertiesScrubType) => void = (scrubProperties) =>
  Object.keys(scrubProperties).forEach((propName) => setPropertyScrub(propName, scrubProperties[propName]));

export const removePropertyScrub: (string) => void = (propertyName) => {
  delete _propertyScrub[propertyName];
};

export const scrubProperties: (any, string) => any = (properties, eventName) => {
  return Object.keys(properties).reduce((acc, propName) => {
    let shouldRemove = false;
    if (_propertyScrub.hasOwnProperty(propName)) {
      const predicate = _propertyScrub[propName];
      if (typeof predicate === 'function') {
        shouldRemove = predicate(properties[propName], eventName, properties);
      } else {
        shouldRemove = true;
      }
    }
    if (!shouldRemove) {
      acc[propName] = properties[propName];
    }
    return acc;
  }, {});
};
