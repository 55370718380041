// @flow
import { createAction } from 'redux-actions';

export const googlePayInitializeAction = createAction('GOOGLE_PAY_INITIALIZE', (url, method) => ({
  url,
  method,
}));

export const googlePayExecuteAction = createAction('GOOGLE_PAY_EXECUTE', (url, method, token) => ({
  url,
  method,
  token,
}));

export const googlePayCancelAction = createAction('GOOGLE_PAY_CANCEL', (url, method) => ({
  url,
  method,
}));

export const googlePayTrackDisplayedAction = createAction('GOOGLE_PAY_TRACK_DISPLAYED');

export const googlePaySetInitializeDataAction = createAction(
  'GOOGLE_PAY_SET_INITIALIZE_DATA',
  (initializeActionData) => ({
    initializeActionData,
  }),
);

export const googlePaySetExecuteDataAction = createAction('GOOGLE_PAY_SET_EXECUTE_DATA', (executeActionData) => ({
  executeActionData,
}));

export const googlePaySetCancelDataAction = createAction('GOOGLE_PAY_SET_CANCEL_DATA', (cancelActionData) => ({
  cancelActionData,
}));
