import styled from '@emotion/styled';
import { H4 } from '@olxui/core';
import { Box } from '@olxui/core/dist/core/Box/Box';

export const StyledPageHeader = styled(Box)`
  border: none;
  margin-bottom: 40px;
  padding: 16px 60px;

  ${({ theme }) => `@media ${theme.mediaQueries.sm}`} {
    padding: unset;
  }
`;

export const StyledBox = styled(Box)`
  max-width: 400px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors['text-global-inverse']};
`;

export const StyledBoxContent = styled(Box.Content)`
  align-items: center;

  & > * {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const StyledBoxTitle = styled(H4)`
  margin-bottom: 15px;
`;
