import { combineActions, handleActions } from 'redux-actions';
import uuid from 'uuid/v4';

import { analyticsGenerateFrontEndAttemptIdAction, analyticsClearStateAction } from '../actions/analytics.ninja';
import { clearStateAction, clearPersistenceState } from '../actions/global';

export const defaultState = {
  frontEndAttemptID: null,
};

export default handleActions(
  {
    [combineActions(clearStateAction, clearPersistenceState)]: () => defaultState,
    [analyticsClearStateAction]: () => defaultState,
    [analyticsGenerateFrontEndAttemptIdAction]: (state, { payload: frontendAttemptId }) => ({
      ...state,
      frontEndAttemptID: frontendAttemptId || uuid(),
    }),
  },
  defaultState,
);
