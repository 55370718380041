// @flow
import { createAction } from 'redux-actions';

export const ccGetDetailsAction = createAction('CC_GET_DETAILS');
export const ccStartAction = createAction('CC_START');
export const ccSuccessAction = createAction('CC_SUCCESS');
export const ccErrorAction = createAction('CC_ERROR');

export const ccAttemptSubmitFormAction = createAction('CC_ATTEMPT_SUBMIT_FORM');
export const ccPaymentStartAction = createAction('CC_PAYMENT_START');

export const ccPaymentAuthoriseForm = createAction('CC_PAYMENT_AUTHORISE_FORM');
export const ccGetInitialData = createAction('CC_GET_INITIAL_DATA');

export const ccInitByConventionSuccess = createAction('CC_INIT_BY_CONVENTION_SUCCESS');
export const ccInitByConventionSelectSaga = createAction('CC_INIT_BY_CONVENTION_SELECT_SAGA');

export const ccRedirectAction = createAction('CC_REDIRECT_ACTION', (status, body) => ({
  status,
  body,
}));

export const ccPaymentSuccessAction = createAction('CC_PAYMENT_SUCCESS', (status, body) => ({
  status,
  body,
}));

export const ccPaymentErrorAction = createAction('CC_PAYMENT_ERROR', (status, body) => ({
  status,
  body,
}));
