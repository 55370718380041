// @flow
import { createAction } from 'redux-actions';

export const ibtSuccess = createAction('IBT_SUCCESS');
export const ibtError = createAction('IBT_ERROR');
export const ibtStart = createAction('IBT_START');

export const ibtStartV2 = createAction('IBT_START_V2');

export const ibtGetBanksAction = createAction('IBT_GET_BANKS');
export const ibtSelectBankAction = createAction('IBT_SELECT_BANK');
export const ibtSelectBankSuccessAction = createAction('IBT_SELECT_BANK_SUCCESS');
export const ibtSelectBankErrorAction = createAction('IBT_SELECT_BANK_ERROR');
export const ibtOfflineBankSelectAttemptAction = createAction('IBT_OFFLINE_BANK_SELECT_ATTEMPT');

export const ibtCancelSelectionAction = createAction('IBT_CANCEL_SELECTION');

export const ibtChangeSearchFilter = createAction('IBT_CHANGE_SEARCH_FILTER');
