import { connect } from 'react-redux';
import { compose, lifecycle, renderNothing } from 'recompose';

import { loadableStart, loadableFinish } from '../../redux/actions/loadable';

const LoadableLoading = compose(
  connect(null, {
    loadableStart,
    loadableFinish,
  }),
  lifecycle({
    componentDidMount() {
      this.props.loadableStart();
    },
    componentWillUnmount() {
      this.props.loadableFinish();
    },
  }),
  renderNothing,
)();

export default LoadableLoading;
