import { apiAction, simpleFetch } from './shared';

export const initialize = ({ url, method }) =>
  simpleFetch({
    url: url,
    method: method,
  });

export const execute = ({ url, method, token }) =>
  apiAction({
    uri: url,
    httpMethod: method,
    payload: {
      token: token,
    },
  });

export const cancel = ({ url, method }) =>
  apiAction({
    uri: url,
    httpMethod: method,
  });
