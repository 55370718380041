// @flow

import React from 'react';

import styled from '@emotion/styled';
import OLoader from '@olxui/core-v10/dist/core/Loading/OLoader';
import XLoader from '@olxui/core-v10/dist/core/Loading/XLoader';

type Props = {
  children?: React.Element<*>,
  isOpen: Boolean,
};

export const FullPageLoader = ({ children, isOpen, ...rest }: Props) =>
  isOpen ? (
    <FixedContainer>
      <BackDrop />
      <Animation />
    </FixedContainer>
  ) : null;

export default FullPageLoader;

let lastAnimation = null;
const Animation = () => {
  if (!lastAnimation) {
    lastAnimation = Math.random() < 0.5 ? <OLoader /> : <XLoader />;

    setTimeout(() => (lastAnimation = null), 5000);
  }

  return lastAnimation;
};

// Styled components

const overlay = `
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

const FixedContainer = styled.div`
  ${overlay}
  z-index: 1051;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    z-index: 2;
  }
`;

const BackDrop = styled.span`
  opacity: 0.9;
  background-color: ${({ theme }) => theme.colors['background-global-secondary']};
  width: 100vw;
  height: 100vh;
  position: absolute;
`;
