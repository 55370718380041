import { connect } from 'react-redux';

import { description } from '../../selectors/paymentMethods';
import { location } from '../../selectors/routing';
import Header from './Header';

const mapStateToProps = (state) => ({
  location: location(state),
  description: description(state),
});

export default connect(mapStateToProps)(Header);
