// @flow

import Loadable from 'react-loadable';

import LoadableLoading from '../components/LoadableLoading';

const getLoadableComponent = (importFn) =>
  Loadable({
    loader: importFn,
    loading: LoadableLoading,
    delay: 200,
  });

export default getLoadableComponent;
