import { handleActions } from 'redux-actions';

import { pmErrorAction, pmStartAction, pmSuccessAction } from '../actions/paymentMethods';

const defaultState = {
  methods: [],
  payment: false,
  loading: false,
  error: false,
  pageTitle: 'Payment Methods',
  flavors: [],
};

export default handleActions(
  {
    [pmStartAction]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [pmSuccessAction]: (
      state,
      { payload: { amount, cancelUrl, customerActionUrl, description, flavors, paymentMethods } },
    ) => ({
      ...state,
      loading: false,
      error: false,
      description,
      methods: paymentMethods,
      payment: amount,
      cancelUrl,
      customerActionUrl,
      flavors,
    }),
    [pmErrorAction]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  defaultState,
);
