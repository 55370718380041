import styled from '@emotion/styled';

export const StyledProgressContainer = styled('div')`
  text-align: center;

  p {
    margin-top: 15px;

    strong {
      font-weight: bold;
    }
  }
`;
