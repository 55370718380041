import { useContext, useEffect } from 'react';

import useAxios from 'axios-hooks';

import { AppContext } from '../../../../contexts';
import { getFormattedPrice } from '../../../../helpers';
import { useLoader } from '../../../../hooks';
import { InitializeRequestResponse, UseUpiTransferInitializeReturn } from './types';

export const useUpiTransferInitialize = (): UseUpiTransferInitializeReturn => {
  const {
    parsedParams: {
      init: { integrator, paymentId, locale },
    },
  } = useContext(AppContext);

  const { showLoading, hideLoading } = useLoader();

  const [{ data, loading, error }] = useAxios<InitializeRequestResponse>(
    `/${integrator.toLowerCase()}/upi/adyen/initialize/${paymentId}`,
  );

  const formattedPrice = getFormattedPrice(data?.context?.grossValue || 0, data?.context?.currency || '', locale);

  useEffect(() => {
    loading ? showLoading() : hideLoading();
  }, [loading]);

  return { data, error, formattedPrice };
};
