import { theme } from '@olxui/core/dist/theme/CoreTheme/coreTheme';

import { AppTheme } from './types';

export const olxClassic: AppTheme = {
  ...theme,
  staticConfig: {
    favIcon: '/icons/favicon-atlas.ico',
  },
};
