import React, { useEffect } from 'react';

import { ThemeProvider } from '@emotion/react';
import { Page } from '@olxui/core-v10/dist/core/Page/Page';
import { ThemeProvider as LegacyThemeProvider } from 'emotion-theming';
import { Helmet } from 'react-helmet';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';

import FullPageLoader from './components/FullPageLoader';
import Header from './components/Header';
import LoadableLoading from './components/LoadableLoading';
import { PortalNode } from './components/Portal/BottomOverlayPortal';
import ProgressLoader from './components/ProgressLoader';
import { getAppConfig } from './config';
import rewRelicConfig from './config/newRelicSnippet';
import { AppContextProvider, LoaderContextProvider } from './contexts';
import { getMessage, addLocaleData } from './helpers/lexemes';
import { getTitleAndSubtitle } from './helpers/route';
import { routes, RouteWithSubRoutes } from './routes';
import { showFullPageLoader as showFullPageLoaderSelector, showProgressLoader } from './selectors/loader';
import { getTheme, GlobalStyle } from './styles';

declare const window: any;

type Props = {
  loading: boolean;
  title: string;
  description: string;
  faviconHref: string;
  isRTL: boolean;
  showProgress: boolean;
  lang: string;
  pageName: string;
  history: {};
  theme: {};
};

const App = ({
  description,
  history,
  isRTL,
  faviconHref,
  loading,
  lang,
  pageName,
  showProgress,
  title,
  theme,
}: Props) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerText = rewRelicConfig[process.env.REACT_APP_RUNTIME_ENVIRONMENT];

    const tag = document.getElementsByTagName('script')[0];

    if (!tag?.parentNode) {
      return;
    }

    tag.parentNode.insertBefore(script, tag);

    // Adds a user agent to New Relic
    try {
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('cUserAgent', navigator.userAgent);
      }
    } catch (err) {
      // nothing to do
    }
  }, []);

  return (
    <AppContextProvider>
      <LegacyThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <LoaderContextProvider>
            <GlobalStyle />
            <Helmet>
              <html lang={lang} dir={isRTL ? 'rtl' : 'ltr'} />
              <link rel="icon" href={faviconHref} />
              <meta name="description" content={description} />
              <title>{title}</title>
            </Helmet>

            <FullPageLoader isOpen={loading} />
            <ProgressLoader isOpen={showProgress} />

            <ConnectedRouter history={history as any}>
              <Page data-test={`${pageName}Page`} backgroundColor="background-global-primary">
                <Header loading={loading} />
                <Page.Section>
                  <Switch>
                    {routes.map((route) => (
                      <RouteWithSubRoutes key={route.path} {...route} />
                    ))}
                  </Switch>
                </Page.Section>
                <PortalNode />
              </Page>
            </ConnectedRouter>
          </LoaderContextProvider>
        </ThemeProvider>
      </LegacyThemeProvider>
    </AppContextProvider>
  );
};

export const ConnectedApp = connect((state: any) => {
  const { pathname } = window.location;
  const theme = getTheme();
  const { title, description } = getTitleAndSubtitle(pathname);

  return {
    description: getMessage(description || 'App.about.aboutUs.text'),
    faviconHref: theme?.staticConfig?.favIcon,
    loading: showFullPageLoaderSelector(state),
    pageName: getPageName(pathname),
    showProgress: showProgressLoader(state),
    theme,
    title: `${getMessage('App.page.title')} | ${getMessage(title)}`,
  };
})(App as any) as any;

export default ({ history }) => {
  const AppWithConfig = Loadable({
    loader: () =>
      getAppConfig().then((result) => {
        addLocaleData(result);
        return result;
      }),
    loading: LoadableLoading as any,
    render: (appConfig) => <ConnectedApp history={history} {...appConfig} />,
  });

  return <AppWithConfig />;
};

const getPageName = (pathname = '') => {
  pathname = pathname.split('/')[2];
  if (!pathname) {
    return '';
  }

  return pathname.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};
