import { ApiV2Response } from '../../types/ApiV2Response';
import { InterceptorType } from './InterceptorType';

export const previouslyCapturedInterceptor: InterceptorType = {
  predicate: (_, payload: ApiV2Response) =>
    payload && payload.status === 'CAPTURED' && payload.message === 'PREVIOUSLY_CAPTURED',
  sideEffect: (payload: ApiV2Response) => {
    switch (payload.version) {
      case 'V1':
        break;
      case 'V2':
      case 'V2.1': {
        if (payload.action.type === 'REDIRECT') {
          window.location.assign(payload.action.uri);
          return null;
        }

        break;
      }
      default: {
        break;
      }
    }

    return payload;
  },
};
