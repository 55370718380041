import { decode } from 'js-base64';
import { parse } from 'query-string';

import { GetParamsReturn, ParsedParamsProps, QueryParamProps } from './types';

export const getUrlParams = (queryParams = window.location.search): GetParamsReturn => {
  const parsedQueryParams = parse(queryParams) as QueryParamProps;

  const parsedParams = {} as ParsedParamsProps;

  if (parsedQueryParams && parsedQueryParams.init) {
    parsedParams.init = JSON.parse(decode(parsedQueryParams.init));
    parsedParams.init = { ...parsedParams.init, locale: parsedParams.init.locale.replace('_', '-').toLowerCase() };
  }

  return {
    parsedParams,
    queryParams: parsedQueryParams,
  };
};
