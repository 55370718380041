import { css } from '@emotion/css-v11';
import { useTheme } from '@emotion/react';

export const useAppIdStyle = (component: string) => {
  const theme = useTheme();

  if (!theme?.components?.[component]) {
    return;
  }

  return css(theme?.components?.[component]);
};
