// @flow
export const APP_ID = 'appId';
export const PAGE_NAME = 'pageName';
export const LOCALE = 'locale';
export const INDEX = `/:${APP_ID}`;
export const PAYMENT_SELECTION = `/:${APP_ID}/payment-selection`;
export const PAYMENT_RESULT = `/:${APP_ID}/payment-result`;
export const CARD = `/:${APP_ID}/credit-card`;
export const STORED_CARD = `/:${APP_ID}/stored-credit-card`;
export const STORED_CARD_STANDALONE = `/:${APP_ID}/tcc`;
export const OVER_THE_COUNTER = `/:${APP_ID}/over-the-counter`;
export const ERROR = `/:${APP_ID}/error`;
export const ABOUT = `/:${APP_ID}/about`;
export const INSTANT_BANK_TRANSFER = `/:${APP_ID}/instant-bank-transfer`;
export const SMS = `/:${APP_ID}/sms`;
export const INTERSTITIAL = `/:${APP_ID}/interstitial`;
export const BANK_TRANSFER_RESULT = `/:${APP_ID}/payment-result-bank-transfer`;
export const OVER_THE_COUNTER_RESULT = `/:${APP_ID}/payment-result-over-the-counter`;
export const MAINTENANCE_PAGE = `/:${APP_ID}/maintenance`;
export const GENERIC = `/:${APP_ID}/:${PAGE_NAME}`;
export const IBT = `/:${APP_ID}/ibt`; // This is a generic page path
export const OTC = `/:${APP_ID}/otc`; // This is a generic page path
export const BANK_TRANSFER = `/:${APP_ID}/bank_transfer`; // This is a generic page path
export const UPI_TRANSFER = `/:${APP_ID}/upi`;
