import { letgo } from './letgo';
import { olxClassic } from './olxClassic';

export const availableThemes = {
  olxClassic,
  letgo,
};

export const themeSelector = {
  tr: availableThemes.letgo,
  default: availableThemes.olxClassic,
};
