import { put, call, select, takeLatest } from 'redux-saga/effects';

import * as concreteApi from '../../api';
import { getPaymentProviderInstance } from '../../paymentProviders';
import {
  paymentInfo as paymentInfoSelector,
  creditCardSelector,
  paymentDetails as paymentDetailsSelector,
} from '../../selectors/creditCard';
import { locale as localeSelector, paymentId as paymentIdSelector } from '../../selectors/global';
import { currentMethodMetaData } from '../../selectors/paymentStatus';
import { location as locationSelector } from '../../selectors/routing';
import { formValues } from '../../selectors/tokenisedPayment';
import { setGlobal as setError } from '../actions/error';
import { paymentFlowFinishedAction } from '../actions/global';
import {
  ccTokenisedPaymentAttemptSubmitFormAction,
  ccTokenisedPaymentMethodRemoveCancelAction,
  ccTokenisedPaymentMethodRemoveConfirmAction,
  ccTokenisedPaymentErrorAction,
  ccTokenisedPaymentStartAction,
  ccTokenisedPaymentSuccessAction,
  ccTokenisedPaymentCancelAction,
  ccTokenisedPaymentAuthoriseForm,
} from '../actions/tokenisedPayment';
import { initializeAction as initializeV2Action } from '../actions/v2';
import { methodTypes } from './analytics/constants';
import * as sharedAnalyticsService from './analytics/shared';
import * as tokenisedAnalyticsService from './analytics/tokenisedPaymentAnalyticsService';
import * as paymentFlowService from './paymentFlowService';

const rejectStates = ['REJECTED', 'ERROR'];

export function* executeTokenisedPayment(
  { action: apiCallOptions, analytics, api, paymentFlow },
  {
    paymentProvider,
    paymentId,
    paymentInfo,
    customerId,
    sessionId,
    cvv,
    location,
    tokenId,
    details,
    locale,
    cardType,
    maskedCardNumber,
  },
) {
  const response = yield paymentProvider
    .makeTokenizedPayment({
      customerId,
      sessionId,
      paymentId,
      paymentInfo,
      tokenId,
      cardDetails: { cvv },
      details,
      locale,
      cardType,
      maskedCardNumber,
    })
    .then((paymentDetails) => api.executePayment(paymentDetails, apiCallOptions))
    .catch((err) => ({ err }));

  const { result: { status } = {}, json = {}, err } = response;
  const { actions = {}, version, message = 'generic', redirectUrl, completedUrl, redirectType, request = {} } = json;

  const action = Object.values(actions)[0];
  const paymentFailed = err || status >= 300 || rejectStates.includes(json.status);

  try {
    if (paymentFailed) {
      throw err || new Error('Payment failed');
    }

    if (action && version === 'V2.1') {
      yield put(initializeV2Action(action));
    } else {
      // Sending to 3DS
      if (redirectType === 'AUTHORIZE_FORM') {
        const action = ccTokenisedPaymentAuthoriseForm({
          requestUrl: redirectUrl,
          request,
        });

        yield tokenisedAnalyticsService.trackTokenisedRedirectAction(action);
        yield put(action);
        yield paymentFlow.redirectViaFormSubmit(action);
        // We don't want to hit paymentFlowFinishedAction in this case
        return;
      } else {
        const successAction = ccTokenisedPaymentSuccessAction(status, json);
        if (redirectType === 'COMPLETED') {
          yield analytics.trackPaymentSuccess(successAction);
        }
        yield put(successAction);
        yield paymentFlow.handlePaymentFlowFinished(successAction);
      }

      yield put(
        paymentFlowFinishedAction({
          type: 'CARD',
          redirectUrl: redirectUrl || completedUrl,
        }),
      );
    }
  } catch (err) {
    const errorAction = ccTokenisedPaymentErrorAction(status, json);
    console.error(err, json);

    yield analytics.trackPaymentFailure(errorAction);
    yield put(setError(message));
    yield put(errorAction);
    yield paymentFlow.redirectToPaymentSelection(errorAction);
  }
}

export function* makeTokenisedPayment({ analytics, api, paymentFlow }, { payload: { tokenId, cardType, action } }) {
  const paymentId = yield select(paymentIdSelector);
  const paymentInfo = yield select(paymentInfoSelector);
  const { customerId, sessionId, psp } = yield select(creditCardSelector);
  const { cvv } = yield select(formValues);
  const location = yield select(locationSelector);
  const details = yield select(paymentDetailsSelector);
  const locale = yield select(localeSelector);
  const { maskedCardNumber } = yield select(currentMethodMetaData);
  const paymentProvider = yield call(getPaymentProviderInstance, psp);

  yield call(
    executeTokenisedPayment,
    {
      api,
      action,
      analytics,
      paymentFlow,
    },
    {
      tokenId,
      paymentProvider,
      paymentId,
      paymentInfo,
      customerId,
      sessionId,
      cvv,
      location,
      details,
      locale,
      cardType,
      maskedCardNumber,
    },
  );
}

const makeMainSaga = ({ behavior, ...params }) =>
  function* tokenisedPaymentSaga() {
    const { analytics, paymentFlow } = params;

    yield takeLatest(ccTokenisedPaymentStartAction, behavior.makeTokenisedPayment, params);

    yield takeLatest(ccTokenisedPaymentCancelAction, paymentFlow.goBack);

    yield takeLatest(
      ccTokenisedPaymentMethodRemoveConfirmAction,
      analytics.trackPaymentMethodRemovalDecision('Remove_Card'),
    );

    yield takeLatest(ccTokenisedPaymentMethodRemoveCancelAction, analytics.trackPaymentMethodRemovalDecision('Cancel'));

    yield takeLatest(
      ccTokenisedPaymentAttemptSubmitFormAction,
      analytics.trackPaymentSubmission(methodTypes.tokenisedPayment),
    );
  };

export default makeMainSaga({
  analytics: {
    ...sharedAnalyticsService,
    ...tokenisedAnalyticsService,
  },
  api: concreteApi,
  behavior: {
    makeTokenisedPayment,
  },
  paymentFlow: paymentFlowService,
});

export const behaviorForTesting = {
  executeTokenisedPayment,
  makeMainSaga,
};
