import getLoadableComponent from '../helpers/getLoadableComponent';

const ErrorPage = getLoadableComponent(() => import('./ErrorPage/ErrorPage'));
const AboutPage = getLoadableComponent(() => import('./AboutPage'));
const CreditCardContainer = getLoadableComponent(() => import('./CreditCardPage/CreditCardContainer'));
const InstantBankTransferContainer = getLoadableComponent(
  () => import('./InstantBankTransferPage/InstantBankTransferContainer'),
);
const PaymentStatusContainer = getLoadableComponent(() => import('./PaymentStatusPage/PaymentStatusContainer'));
const PaymentSelectionPage = getLoadableComponent(() => import('./PaymentSelectionPage'));
const SmsPage = getLoadableComponent(() => import('./SmsPage/SmsPage'));
const InterstitialPage = getLoadableComponent(() => import('./interstitialPage/InterstitialContainer'));
const BankTransferResultPage = getLoadableComponent(() => import('./BankTransferResultPage'));
const OverTheCounterResultPage = getLoadableComponent(() => import('./OverTheCounterResultPage'));
const MaintenancePage = getLoadableComponent(() => import('./MaintenancePage/MaintenancePage'));

const GenericPage = getLoadableComponent(() => import('./GenericPage'));

export {
  AboutPage,
  BankTransferResultPage,
  CreditCardContainer,
  InstantBankTransferContainer,
  InterstitialPage,
  PaymentStatusContainer,
  PaymentSelectionPage,
  SmsPage,
  MaintenancePage,
  OverTheCounterResultPage,
  ErrorPage,
  GenericPage,
};

export * from './UpiPage';
