// @flow

import { flow, get } from 'lodash/fp';
import { select } from 'redux-saga/effects';

import { getCurrentRoute } from '../../../helpers/matchUtils';
import * as paths from '../../../pathConstants';
import { currentMethodType as currentMethodTypeSelector } from '../../../selectors/paymentStatus';
import { location as locationSelector } from '../../../selectors/routing';
import tracker from './compositeTracker';
import { pageNames, methodTypesPerPath } from './constants';
import { setPropertiesScrub } from './propertiesScrubHelper';
import { propertiesScrubConfig } from './propertiesScrubHelper/config';

setPropertiesScrub(propertiesScrubConfig);

export function* currentPageAndMethod() {
  const { path: pathname } = getCurrentRoute();
  const pageName = pageNames[pathname];
  let methodType;

  if (pageName === 'method') {
    methodType = methodTypesPerPath[pathname];
  } else {
    methodType = yield select(currentMethodTypeSelector);
  }

  return {
    pageName,
    methodType,
  };
}

export function* trackPageView(givenPathname: string): Generator<*, *, *> {
  const pathname = yield typeof givenPathname === 'string'
    ? givenPathname
    : select(flow(locationSelector, get('pathname')));

  if (pathname === paths.PAYMENT_RESULT) {
    return;
  }
  yield tracker.trackPage({ url: pathname });
}

// method type an be derived from currentPageAndMethod,
// but if supplied explicitly it should be taken instead of that method.
// The use case is the response from payment status which also provides
// the method_type to distinguish 3ds flow.
function* getMethodType({ methodType, tokenised }) {
  const bodyMethodType =
    methodType !== 'CARD' && methodType !== 'TOKENIZED_CARD' ? methodType : !!tokenised ? 'stored_card' : 'new_card';

  return bodyMethodType || (yield currentPageAndMethod()).methodType;
}

const trackPaymentResult = (paymentResult: boolean | 'timeout') =>
  function* doTrackPaymentResult({ payload }): Generator<*, *, *> {
    const body = payload.body || payload;
    const { encryptedAttemptId, applied3DSecure } = body;

    const methodType = yield getMethodType(body);
    yield tracker.trackEvent({
      eventName: 'Payment_Execution',
      params: {
        encryptedAttemptId,
        methodType,
        paymentResult,
        applied3DSecure: !!applied3DSecure,
      },
    });
  };

export const trackPaymentSuccess = trackPaymentResult(true);
export const trackPaymentFailure = trackPaymentResult(false);
export const trackPaymentTimeout = trackPaymentResult('timeout');

export function* trackGoBack() {
  const { methodType, pageName } = yield currentPageAndMethod();
  yield tracker.trackEvent({
    eventName: `${pageName}_Back_Button`,
    params: {
      methodType: pageName === 'payment_selection' ? null : methodType,
    },
  });
}

export const trackPaymentSubmission = (methodType: string) =>
  function* doTrackPaymentSubmission({ payload }) {
    yield tracker.trackEvent({
      eventName: 'Payment_Submitted',
      params: {
        formValid: payload.valid,
        methodType,
      },
    });
  };

export const behaviorForTesting = {
  trackPaymentResult,
};
