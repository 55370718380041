import ARROW_DOWN from '@olxui/core-v10/dist/core/Icon/icons/default/ArrowDown';
import ARROW_LEFT from '@olxui/core-v10/dist/core/Icon/icons/default/ArrowLeft';
import ARROW_RIGHT from '@olxui/core-v10/dist/core/Icon/icons/default/ArrowRight';
import CLOSE from '@olxui/core-v10/dist/core/Icon/icons/default/Close';
import CARD from '@olxui/core-v10/dist/core/Icon/icons/default/CreditCard';
import INFO from '@olxui/core-v10/dist/core/Icon/icons/default/Info';
import CVV_CARD from '@olxui/core-v10/dist/core/Icon/icons/illustrations/CreditCard';
import LOGO from '@olxui/core-v10/dist/core/Icon/icons/illustrations/Logo';
import SHOCKED from '@olxui/core-v10/dist/core/Icon/icons/illustrations/Shocked';

import CVV from '../../svg/cvv.svg';
import LETGO_LOGO from '../../svg/letgo-logo.svg';
import MOBILE_WALLET from '../../svg/mobile-wallet.svg';
import INSTANT_BANK_TRANSFER from '../../svg/pm-bank-transfer-instant.svg';
import BANK_TRANSFER from '../../svg/pm-bank-transfer-manual.svg';
import SMS from '../../svg/sms.svg';

const staticPath = '/images';

const ALFAMART = `${staticPath}/doku_alfamart.svg`;
const AMEX = `${staticPath}/americanexpress.svg`;
const BCA_VA = `${staticPath}/doku_bca_va.svg`;
const BLIK = `${staticPath}/blik.svg`;
const BRI_VA = `${staticPath}/doku_bri_va.svg`;
const CIMB_VA = `${staticPath}/doku_cimb_va.svg`;
const CLICKPAY = `${staticPath}/doku_mandiri_va.svg`;
const DANAMON_VA = `${staticPath}/doku_danamon_va.svg`;
const DINERS_CLUB = `${staticPath}/ccicon-dinersclub.svg`;
const DISCOVER = `${staticPath}/ccicon-discover.svg`;
const FAWRY = `${staticPath}/fawry.svg`;
const GENERIC_CARD = `${staticPath}/cc-generic.svg`;
const INDOMARET = `${staticPath}/doku_indomaret.svg`;
const JCB = `${staticPath}/jcb.svg`;
const KLIK_BCA = `${staticPath}/doku_bca_klikpay.svg`;
const MAESTRO = `${staticPath}/ccicon-maestro.svg`;
const MAGNIFYING = `${staticPath}/magnifying-glass.svg`;
const MAGNIFYING_GLASS = `${staticPath}/grey-search.png`;
const MANDIRI_VA = `${staticPath}/doku_mandiri_va.svg`;
const MASTERCARD = `${staticPath}/mastercard.svg`;
const MASTERCARD_SECURECODE = `${staticPath}/mastercard-securecode.svg`;
const OVER_THE_COUNTER = `${staticPath}/pm-over-the-counter.svg`;
const PADLOCK = `${staticPath}/padlock.svg`;
const PERMATA_BERSAMA = `${staticPath}/doku_permata_atm.svg`;
const PRIVAT24 = `${staticPath}/Privat24.svg`;
const PROSTIR = `${staticPath}/prostir.svg`;
const PSE = `${staticPath}/pse.svg`;
const RUPAY = `${staticPath}/cc-rupay.png`;
const SUCCESS = `${staticPath}/success.svg`;
const UNIONPAY = `${staticPath}/ccicon-unionpay.svg`;
const UPI = `${staticPath}/upi.svg`;
const VERIFIED_VISA = `${staticPath}/verified-visa.svg`;
const VISA = `${staticPath}/visa.svg`;
const WALLET = `${staticPath}/doku_wallet.svg`;
const WRONG = `${staticPath}/wrong.svg`;
const GOOGLE_PAY = `${staticPath}/google-pay.svg`;

export const commonIcons = {
  ALFAMART,
  AMERICAN_EXPRESS: AMEX,
  AMEX,
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  BANK_TRANSFER,
  BCA_VA,
  BLIK,
  BRI_VA,
  CARD,
  CIMB_VA,
  CLICKPAY,
  CLOSE,
  CVV,
  CVV_CARD,
  DANAMON_VA,
  DINERS_CLUB,
  DISCOVER,
  DISCOVERY: DISCOVER,
  DOKU_WALLET: WALLET,
  FAWRY,
  GENERIC: GENERIC_CARD,
  GOOGLE_PAY,
  INDOMARET,
  INFO,
  INSTANT_BANK_TRANSFER,
  JCB,
  KLIK_BCA,
  LOGO,
  MAESTRO,
  MAGNIFYING,
  MAGNIFYING_GLASS,
  MANDIRI_VA,
  MASTERCARD,
  MASTERCARD_SECURECODE,
  MOBILE_WALLET,
  MOCK: GENERIC_CARD,
  OLD_MAESTRO: MAESTRO,
  OVER_THE_COUNTER,
  PADLOCK,
  PERMATA_BERSAMA,
  PRIVAT24,
  PROSTIR,
  PSE,
  RUPAY,
  SHOCKED,
  SMS,
  SUCCESS,
  UNIONPAY,
  UPI,
  VERIFIED_VISA,
  VISA,
  WALLET,
  WRONG,
};

const latamIcons = {
  ...commonIcons,
  BANK_TRANSFER: PSE,
  INSTANT_BANK_TRANSFER: PSE,
};

export const apps = {
  id: {
    ...commonIcons,
    OVER_THE_COUNTER: INSTANT_BANK_TRANSFER,
  },
  in: commonIcons,
  pk: commonIcons,
  pl: commonIcons,
  ua: commonIcons,
  za: commonIcons,

  ar: latamIcons,
  co: latamIcons,
  cr: latamIcons,
  ec: latamIcons,
  gt: latamIcons,
  pa: latamIcons,
  pe: latamIcons,
  sv: latamIcons,

  lb: commonIcons,
  eg: {
    ...commonIcons,
    OVER_THE_COUNTER: FAWRY,
  },

  tr: {
    ...commonIcons,
    LOGO: LETGO_LOGO,
  },
};
