// @flow

import { get } from 'lodash/fp';
import { LOCATION_CHANGE } from 'react-router-redux';
import { put, select, fork, take, call } from 'redux-saga/effects';

import parseInitParams from '../../helpers/parseInitParams';
import { location as locationSelector } from '../../selectors/routing';
import { applicationInitializedAction, setEnvVarsAction, setGlobals } from '../actions/global';

export function* setEnvVars() {
  const { search } = yield select(locationSelector);
  try {
    const { result, vars } = yield call(parseInitParams, search);
    const env = yield select(get('global.env'));

    if (vars && vars.init && (!env || !env.init || env.init !== vars.init)) {
      yield put(setGlobals(result));
    }

    yield put(setEnvVarsAction(vars));
  } catch (e) {
    // TODO sentry
    /* incase anyone is fudging parameters */
    throw e;
  } finally {
    yield put(applicationInitializedAction());
  }
}

export function* mainSagaFork() {
  yield take(LOCATION_CHANGE);
  yield call(setEnvVars);
}

export const makeMainSaga = ({ behavior }) =>
  function* initSaga() {
    // NOTE blocking, refactor to fork+take if we have more actions.
    // The point is that this needs to be done just once.
    yield fork(mainSagaFork);
  };

export const behaviorForTesting = {
  makeMainSaga,
};

export default makeMainSaga({
  behavior: {
    setEnvVars,
  },
});
