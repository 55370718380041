// @flow

import compositeTracker from './compositeTracker';

type PaymentMethodRemovalDecision = 'Remove_Card' | 'Cancel';

export const trackPaymentMethodRemovalDecision = (
  decision: PaymentMethodRemovalDecision,
  tracker: Tracker = compositeTracker,
) =>
  function* doTrackPaymenyMethodRemovalDecision(): Generator<*, *, *> {
    yield tracker.trackEvent({
      eventName: 'Card_Remove_Button',
      params: {
        actionType: decision,
      },
    });
  };

const trackTokenisedRedirectActionWith = (tracker: Tracker) =>
  function* doTrackTokenisedRedirectAction() {
    yield tracker.trackEvent({
      eventName: 'Redirection_3DS',
      params: {
        methodType: 'tokenised',
      },
    });
  };

export const trackTokenisedRedirectAction = trackTokenisedRedirectActionWith(compositeTracker);
