// @flow

const issuerIds = {
  eurobank: '56',
  raiffeisen: '48',
  ideabank: '81',
};

export const openingHours = {
  [issuerIds.eurobank]: {
    hour: {
      from: new Date('1970-01-01T03:00:00.000Z'),
      to: new Date('1970-01-01T23:00:00.000Z'),
    },
  },
  [issuerIds.ideabank]: {
    hour: {
      from: new Date('1970-01-01T03:00:00.000Z'),
      to: new Date('1970-01-01T22:00:00.000Z'),
    },
  },
  [issuerIds.raiffeisen]: {
    day: {
      from: 1,
      to: 5,
    },
    hour: {
      from: new Date('1970-01-01T08:00:00.000Z'),
      to: new Date('1970-01-01T21:00:00.000Z'),
    },
  },
};
