import { handleActions } from 'redux-actions';

import {
  googlePaySetInitializeDataAction,
  googlePaySetExecuteDataAction,
  googlePaySetCancelDataAction,
} from '../actions/googlePay';

export const defaultState = {
  initializeActionData: null,
  executeActionData: null,
  cancelActionData: null,
};

const googlePay = handleActions(
  {
    [googlePaySetInitializeDataAction]: (state, { payload }) => ({
      ...state,
      initializeActionData: payload.initializeActionData,
    }),
    [googlePaySetExecuteDataAction]: (state, { payload }) => ({
      ...state,
      executeActionData: payload.executeActionData,
    }),
    [googlePaySetCancelDataAction]: (state, { payload }) => ({
      ...state,
      cancelActionData: payload.cancelActionData,
    }),
  },
  defaultState,
);

export default googlePay;
