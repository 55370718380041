import type { InterceptorType } from './InterceptorType';
import { logRequestStatus } from './logRequestStatus';
import { previouslyCapturedInterceptor } from './previouslyCapturedInterceptor';
import { unauthorizedInterceptor } from './unauthorizedInterceptor';

// This is a pipe, so if it matters place the interceptor in the correct location
export const responseInterceptors: InterceptorType[] = [
  logRequestStatus,
  previouslyCapturedInterceptor,
  unauthorizedInterceptor,
];
