import { createContext, FC } from 'react';

import { configureBaseRequest } from '../../api';
import { getUrlParams } from '../../helpers';

export const AppContext = createContext<ReturnType<typeof getUrlParams>>({} as any);

export const AppContextProvider: FC = ({ children }) => {
  const params = getUrlParams();

  configureBaseRequest({ token: params.queryParams?.token });

  return <AppContext.Provider value={{ ...params }}>{children}</AppContext.Provider>;
};
