// @flow

import { combineActions, handleActions } from 'redux-actions';

import type { ApiCallOptions } from '../../api/shared';
import type { PaymentMethod } from '../../types/PaymentMethod';
import { ccPaymentStartAction, ccPaymentErrorAction, ccPaymentSuccessAction } from '../actions/creditCard';
import { clearStateAction, clearPersistenceState } from '../actions/global';
import {
  psStartAction,
  psSuccessAction,
  psErrorAction,
  psTimeoutAction,
  psClearTimeoutAction,
  psStartTokenisedFlowAction,
  psSetMethodAction,
  psPollForStatusAction,
  psResetTimeout,
} from '../actions/paymentStatus';
import {
  ccTokenisedPaymentCancelAction,
  ccTokenisedPaymentStartAction,
  ccTokenisedPaymentErrorAction,
  ccTokenisedPaymentSuccessAction,
} from '../actions/tokenisedPayment';

// TODO trim redundant properties from this fat boi.
type State = {
  status: boolean,
  loading: boolean,
  error: boolean,
  currentMethod?: PaymentMethod,
  isModalOpen: boolean,
  interstitalVisible: boolean,
  allowPayment: boolean,
  statusRequestConfig?: ApiCallOptions,
  timedOut: boolean,
};

export const defaultState: State = {
  status: false,
  loading: false,
  error: false,
  currentMethod: undefined,
  isModalOpen: false,
  interstitialVisible: false,
  allowPayment: true,
  statusRequestConfig: undefined,
  timedOut: false,
};

export default handleActions(
  {
    [combineActions(clearStateAction, clearPersistenceState)]: () => defaultState,
    [ccTokenisedPaymentCancelAction]: (state) => ({
      ...state,
      loading: false,
      error: false,
      currentMethod: undefined,
    }),
    [psResetTimeout]: (state) => ({
      ...state,
      timedOut: false,
    }),
    [psSetMethodAction]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      currentMethod: payload,
      timedOut: false,
    }),
    [psStartAction]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [psSuccessAction]: (state, { payload }) => ({
      ...state,
      status: payload,
      loading: false,
      error: false,
    }),
    [psErrorAction]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [psStartTokenisedFlowAction]: (state) => ({
      ...state,
      isModalOpen: true,
    }),
    [psTimeoutAction]: (state, { payload = {} }) => ({
      ...state,
      status: payload,
      interstitialVisible: payload.status !== 'WAITING_PSP_ACTION',
      timedOut: true,
    }),
    [combineActions(psClearTimeoutAction, psSuccessAction, psErrorAction, psStartAction)]: (state) => ({
      ...state,
      interstitialVisible: false,
    }),
    [combineActions(ccPaymentStartAction, ccTokenisedPaymentStartAction)]: (state) => ({
      ...state,
      allowPayment: false,
    }),
    [ccTokenisedPaymentStartAction]: (state) => ({
      ...state,
      confirmingTokenized: true,
    }),
    [combineActions(
      ccPaymentSuccessAction,
      ccPaymentErrorAction,
      ccTokenisedPaymentSuccessAction,
      ccTokenisedPaymentErrorAction,
    )]: (state) => ({
      ...state,
      allowPayment: true,
      confirmingTokenized: false,
    }),
    [psPollForStatusAction]: (state, { payload }) => ({
      ...state,
      statusRequestConfig: {
        ...payload,
        // TODO discuss this with backend, we need to unify the uri property naming.
        url: payload.url || payload.uri || payload.pollUri,
      },
    }),
  },
  defaultState,
);
