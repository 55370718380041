// @flow
import { put } from 'redux-saga/effects';

import { analyticsGenerateFrontEndAttemptIdAction } from '../../actions/analytics.ninja';
import tracker from './compositeTracker';
import { methodTypes } from './constants';

export function* generateFrontendAttemptId() {
  const { search, pathname } = window.location;
  const urlParams = new URLSearchParams(search);
  const frontendAttemptId = urlParams.get('frontendAttemptId');
  yield put(analyticsGenerateFrontEndAttemptIdAction(frontendAttemptId));
  if (frontendAttemptId) {
    urlParams.delete('frontendAttemptId');
    window.history.replaceState({}, '', `${pathname}?${urlParams}`);
  }
}

export function* trackPaymentMethodSelected(action): Generator<*, *, *> {
  const { payload: { methodType, tokenized } = {} } = action || {};
  if (!methodType) {
    __DEV__ && console.warn('Attempt to track Payment_Selection_Method_Selected without methodType');
    return;
  }

  yield generateFrontendAttemptId();
  let methodTypeValue;

  if (methodType === 'CARD') {
    methodTypeValue = tokenized ? methodTypes.tokenisedPayment : methodTypes.creditCard;
  } else if (methodType === 'TOKENIZED_CARD') {
    methodTypeValue = 'stored_card';
  } else if (methodType === 'BANK_TRANSFER') {
    methodTypeValue = 'MANUAL_BANK_TRANSFER';
  } else if (methodType === 'INSTANT_BANK_TRANSFER-V2') {
    methodTypeValue = 'INSTANT_BANK_TRANSFER';
  } else {
    methodTypeValue = methodType;
  }

  yield tracker.trackEvent({
    eventName: 'Payment_Selection_Method_Selected',
    params: {
      methodType: methodTypeValue,
    },
  });
}
