// @flow

import { handleActions } from 'redux-actions';

import {
  genClear,
  genInitializeStart,
  genInitializeSuccess,
  selectOfflineBankAction,
  onChangeFilter,
  closeOfflineBankNotice,
} from '../actions/generic';

type State = {
  populated: boolean,
  version?: string,
  status?: string,
  psp?: string,
  options?: Array<any>,
  description?: string,
  grossValue?: number,
  smsSurcharge?: number,
  currency?: string,
  offlineModalIsOpen?: boolean,
};

export const defaultState: State = {
  populated: false,
  searchFilter: '',
  offlineModalIsOpen: false,
};

export default handleActions(
  {
    [genClear]: () => defaultState,
    [genInitializeStart]: (state) => ({
      ...state,
      offlineModalIsOpen: false,
    }),
    [genInitializeSuccess]: (
      state,
      { payload: { version, status, psp, options, context: { grossValue, smsSurcharge, currency, description } = {} } },
    ) => ({
      ...state,
      populated: true,
      version,
      status,
      psp,
      options,
      grossValue,
      smsSurcharge,
      currency,
      description,
    }),
    [selectOfflineBankAction]: (state) => ({
      ...state,
      offlineModalIsOpen: true,
    }),
    [closeOfflineBankNotice]: (state) => ({
      ...state,
      offlineModalIsOpen: false,
    }),
    [onChangeFilter]: (
      state,
      {
        payload: {
          target: { value },
        },
      },
    ) => ({
      ...state,
      searchFilter: value || '',
    }),
  },
  defaultState,
);
