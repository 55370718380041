// @flow

import * as paths from '../../../pathConstants';

export const pageNames = {
  [paths.INDEX]: 'payment_selection',
  [paths.PAYMENT_SELECTION]: 'payment_selection',
  [paths.PAYMENT_RESULT]: 'payment_result',
  [paths.ABOUT]: 'aboutolx',
  [paths.SMS]: 'method',
  [paths.CARD]: 'method',
  [paths.STORED_CARD]: 'method',
  [paths.INSTANT_BANK_TRANSFER]: 'method',
  [paths.BANK_TRANSFER_RESULT]: 'manual_bank_transfer_result',
  [paths.INTERSTITIAL]: 'interstitial',
  [paths.BANK_TRANSFER]: 'method',
  [paths.IBT]: 'method',
  [paths.GENERIC]: 'method',
  [paths.MAINTENANCE_PAGE]: 'maintenance',
  PIN: 'pin', // not an actual page (iframe step), but is treated as a page from analytics pov.
};

export const methodTypes = {
  creditCard: 'new_card',
  tokenisedPayment: 'stored_card',
  sms: 'sms',
  bankTransfer: 'bank_transfer',
  manualBankTransfer: 'manual_bank_transfer',
  instantBankTransfer: 'instant_bank_transfer',
};

export const methodTypesPerPath = {
  [paths.BANK_TRANSFER]: methodTypes.bankTransfer,
  [paths.CARD]: methodTypes.creditCard,
  [paths.STORED_CARD]: methodTypes.tokenisedPayment,
  [paths.INSTANT_BANK_TRANSFER]: methodTypes.instantBankTransfer,
  [paths.SMS]: methodTypes.sms,
  [paths.IBT]: methodTypes.instantBankTransfer,
};
