// @flow

import { LOCATION_CHANGE } from 'react-router-redux';
import { put, takeLatest, all, call, select } from 'redux-saga/effects';

import { simpleFetch, orchestrationHost } from '../../api/shared';
import {
  paymentId as paymentIdSelector,
  action as actionSelector,
  integrator as integratorSelector,
  psp as pspSelector,
} from '../../selectors/generic';
import {
  genClear,
  genInitializeStart,
  genInitializeSuccess,
  genInitializeFailure,
  genActionAPIStart,
  genActionAPISuccess,
  genActionAPIFailure,
  genInitByConvention,
} from '../actions/generic';
import { paymentFlowFinishedAction } from '../actions/global';
import { redirect, redirectViaFormSubmit } from './paymentFlowService';

export function* initByConvention({
  payload: { integrator, pageName, paymentId, psp, action, successAction, failureAction } = {},
}) {
  if (!successAction) {
    throw new ReferenceError('initByConvention must be provided with successAction in the payload');
  }
  if (!failureAction) {
    throw new ReferenceError('initByConvention must be provided with failureAction in the payload');
  }
  if (!integrator) {
    integrator = yield select(integratorSelector);
    if (typeof integrator === 'string') {
      integrator = integrator.toLowerCase();
    }
  }
  if (!paymentId) {
    paymentId = yield select(paymentIdSelector);
  }

  if (!action) {
    action = yield select(actionSelector);
  }

  if (!psp) {
    psp = yield select(pspSelector);
  }

  if (!(integrator && paymentId && action && psp && pageName)) {
    return yield put(
      failureAction({
        message: 'One of the parameters required for init by convention is missing.',
        params: {
          integrator,
          pageName,
          paymentId,
          psp,
          action,
        },
      }),
    );
  }

  try {
    const init = yield call(makeCall, {
      method: 'GET',
      url: `${orchestrationHost()}/${integrator}/${pageName}/${psp}/${action}/${paymentId}`,
    });
    const submitAction = init.actions && init.actions.submit;

    if (submitAction) {
      yield handleAction(submitAction);
    }
    yield put(successAction(init));
  } catch (err) {
    yield put(failureAction(err));
  }
}

export function* initGenPage({ payload }) {
  return yield call(initByConvention, {
    payload: {
      ...payload,
      successAction: genInitializeSuccess,
      failureAction: genInitializeFailure,
    },
  });
}

export async function makeCall({ url, method, params }: { url: string, method: string, params?: any }) {
  const options: any = {
    method: method.toUpperCase(),
    headers: {},
  };

  if (options.method === 'POST') {
    options.body = JSON.stringify(params);
    options.headers = {
      'Content-Type': 'application/json',
    };
  }

  if (url?.search(/adyen/g) > 0) {
    options.headers['Browser-Width'] = screen.width.toString();
    options.headers['Browser-Height'] = screen.height.toString();
    options.headers['Browser-Color-Depth'] = screen.colorDepth.toString();
    options.headers['Browser-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    options.headers['X-Client-User-Agent'] = navigator.userAgent;
  }

  try {
    return (await simpleFetch({ url, options })).json;
  } catch (err) {
    // TODO: report to NewRelic ?
    // propagate error
    throw err;
  }
}

export function* apiActionHandler({ payload: { httpMethod, params, uri } = {} }) {
  try {
    const res = yield call(makeCall, { method: httpMethod, url: uri, params });
    yield put(genActionAPISuccess(res));
  } catch (err) {
    // TODO: report to NewRelic ?
    console.error(err);
    yield put(genActionAPIFailure(err));
  }
}

export function* redirectFormAction(payload) {
  const flowFinishedAction = yield call(paymentFlowFinishedAction, {
    requestUrl: payload.uri,
    request: payload.params,
  });

  yield call(redirectViaFormSubmit, flowFinishedAction);
}

export function* handleApiActionResult({ payload: { action, actions, version } }) {
  if (version === 'V2.1' && actions.submit) {
    yield handleAction(actions.submit);
  } else if (action) {
    yield handleAction(action);
  }
}

function handleAction(action) {
  switch (action.type) {
    // Add action types here as needed
    case 'REDIRECT':
      if (action.httpMethod.toUpperCase() === 'GET') {
        return call(redirect, { payload: { url: action.uri } });
      }
      break;

    case 'REDIRECT_FORM':
      return call(redirectFormAction, action);

    default:
      // TODO: Handle default action. Perhaps notify new relic.
      break;
  }
}

export function* locationChangeHandler() {
  yield put(genClear());
}

export default function* rootSaga() {
  yield all([
    takeLatest(genInitializeStart, initGenPage),
    takeLatest(genActionAPIStart, apiActionHandler),
    takeLatest(genActionAPISuccess, handleApiActionResult),
    takeLatest(LOCATION_CHANGE, locationChangeHandler),
    takeLatest(genInitByConvention, initByConvention),
    // TODO: Handle case where the init call fails (genInitializeFailure)?
  ]);
}
