// TODO migrate from thunk to saga.
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import rootReducer from './redux/reducers';

export let store;
export const history = createHistory();

export const getState = () => {
  return store ? store.getState() : {};
};

export default function configureStore(runSagas) {
  if (store) {
    return store;
  }

  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose; // eslint-disable-line

  store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, sagaMiddleware, routerMiddleware(history))));
  runSagas(sagaMiddleware);

  return store;
}
