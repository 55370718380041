import { useContext } from 'react';

import { LoaderContext } from '../../contexts';

interface useLoaderReturn {
  showLoading: () => void;
  hideLoading: () => void;
}

export const useLoader = (): useLoaderReturn => {
  const { toggleLoading } = useContext(LoaderContext);

  const showLoading = () => toggleLoading();
  const hideLoading = () => toggleLoading();

  return { showLoading, hideLoading };
};
