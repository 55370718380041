import React from 'react';

import { Global, useTheme, css } from '@emotion/react';

import mq from './mediaQueries';

export const GlobalStyle = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        html {
          font-family: 'Geomanist', Helvetica;
          color: ${theme.colors['text-brand-primary']};
        }
        * {
          box-sizing: border-box;
          border: 0;
          font-size: 100%;
          font: inherit;
          margin: 0;
          padding: 0;
          vertical-align: baseline;
        }

        html[dir='rtl'] & .rtl-icon {
          transform: scale(-1, 1);
        }

        html[dir='rtl'] & p[dir='ltr'] {
          text-align: right;
        }

        .pointer {
          cursor: pointer;
        }

        ${mq.gt('phablet')} {
          body,
          #root > main {
            background-color: ${theme.colors['background-global-secondary']};
            ${theme?.components?.Body}
          }
        }
      `}
    />
  );
};
