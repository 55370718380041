import React, { useEffect, useState } from 'react';

import { P3 } from '@olxui/core';
import { ProgressBar } from '@olxui/core/dist/core/ProgressBar/ProgressBar';

import { StyledProgressContainer } from './styled';
import { CountdownTimerProps } from './types';

export const CountdownTimer = ({
  timeInSeconds,
  activeMessage,
  onFinished,
  minutesText = 'minutes',
  secondsText = 'seconds',
}: CountdownTimerProps) => {
  const [countDown, setCountDown] = useState(timeInSeconds);

  const minutes = Math.trunc(countDown / 60);
  const seconds = String(countDown % 60).padStart(2, '0');

  useEffect(() => {
    if (countDown === 0) {
      onFinished();
      return;
    }

    const timeout = setTimeout(() => {
      setCountDown(countDown - 1);
    }, 1000);

    return () => clearInterval(timeout);
  }, [countDown]);

  return (
    <StyledProgressContainer data-testid="countdownTimer">
      <ProgressBar percentCompleted={countDown / 3} height={10} />
      <P3>
        {activeMessage}
        <strong>
          {minutes > 0 ? ` ${minutes}:` : ' '}
          {seconds} {minutes > 0 ? minutesText : secondsText}
        </strong>
      </P3>
    </StyledProgressContainer>
  );
};
