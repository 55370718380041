import { getOr } from 'lodash/fp';
import { combineActions, handleActions } from 'redux-actions';

import { ccPaymentErrorAction } from '../actions/creditCard';
import { clearGlobalErrorAction } from '../actions/error';
import { psErrorAction, psSetMethodAction } from '../actions/paymentStatus';
// TODO uncomment when error handling is updated to new Fortumo standards.
// import {
//   smsPaymentErrorAction,
//   smsPaymentStatusErrorAction
// } from "../actions/smsPayment";

export const defaultState = {
  global: false,
  card: false,
};

export default handleActions(
  {
    [combineActions('PL_GLOBAL_ERROR', psErrorAction)]: (state, action) => ({
      ...state,
      // The getOr doesn't work as expected when BE returns the value "null" in the message
      global: getOr('generic', 'payload.message', action) || 'generic',
    }),
    [ccPaymentErrorAction]: (state, action) => {
      const message = getOr('generic', 'payload.body.message', action);
      const result = {
        ...state,
      };

      if (message === 'RE_ENTER_CARD_DATA') {
        result.card = message;
      } else {
        result.global = message;
      }

      return result;
    },
    // TODO uncomment when error handling is updated to new Fortumo standards.
    // [combineActions(smsPaymentErrorAction, smsPaymentStatusErrorAction)]: (
    //   state,
    //   { payload: { code } }
    // ) => ({
    //   ...state,
    //   global: code
    // }),
    [combineActions(psSetMethodAction, clearGlobalErrorAction)]: (state, { payload = {} }) =>
      payload.skipClearGlobalError ? state : defaultState,
  },
  defaultState,
);
