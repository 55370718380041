// @flow

import { get } from 'lodash/fp';
import { LOCATION_CHANGE } from 'react-router-redux';
import { actionTypes as FormActionTypes, change, untouch } from 'redux-form';
import { delay } from 'redux-saga';
import { cancel, fork, put, select, take, takeLatest } from 'redux-saga/effects';

import * as concreteApi from '../../../api';
import getAppId from '../../../helpers/getAppId';
import populateParams from '../../../helpers/populateParams';
import * as paths from '../../../pathConstants';
import { shouldInitByConvention } from '../../../selectors/generic';
import { methods as methodsSelector } from '../../../selectors/paymentMethods';
import {
  currentMethodType as currentMethodTypeSelector,
  currentMethodVersion as currentMethodVersionSelector,
} from '../../../selectors/paymentStatus';
import { cardTokenId } from '../../../selectors/routing';
import {
  ccAttemptSubmitFormAction,
  ccGetDetailsAction,
  ccGetInitialData,
  ccPaymentErrorAction,
  ccStartAction,
  ccSuccessAction,
  ccErrorAction,
  ccInitByConventionSuccess,
  ccInitByConventionSelectSaga,
} from '../../actions/creditCard';
import { clearGlobalErrorAction } from '../../actions/error';
import { genInitByConvention } from '../../actions/generic';
import { psSetMethodAction } from '../../actions/paymentStatus';
import { methodTypes } from '../analytics/constants';
import * as sharedAnalyticsService from '../analytics/shared';
import * as paymentFlowService from '../paymentFlowService';
import { getMethods } from '../paymentMethodsSaga';

type SagaLoaderSelection = {
  [version: string]: () => Promise<{
    default: () => Generator<void, void, void>,
  }>,
};

const sagas: SagaLoaderSelection = {
  V1: () => import('./creditCardSagaV1'),
  V2: () => import('./creditCardSagaV2'),
  'V2.1': () => import('./creditCardSagaV2'),
};

function* clearCCData() {
  yield put(change('creditCard', 'cvv', ''));
  yield put(untouch('creditCard', 'cvv'));
  yield put(change('creditCard', 'ccexpiry', ''));
  yield put(untouch('creditCard', 'ccexpiry'));
  yield put(change('creditCard', 'ccnumber', ''));
  yield put(untouch('creditCard', 'ccnumber'));
}

const locationChangeOrFieldChange = ({ type }) => type === LOCATION_CHANGE || type === FormActionTypes.CHANGE;

function* clearErrorOnActivity() {
  yield take(locationChangeOrFieldChange);
  yield put(clearGlobalErrorAction());
}

function* handleCCDataError() {
  yield clearCCData();
  yield clearErrorOnActivity();
}

const ccDataErrorActions = ({ type, payload }) =>
  type === ccPaymentErrorAction.toString() && get('body.message')(payload) === 'RE_ENTER_CARD_DATA';

const ALLOWED_METHOD_TYPES = {
  CARD: true,
  'CARD-V2': true,
};

let currentSaga;

function* selectSagaForInitByConvention(_, { payload = {} }) {
  const currentMethodVersion = get('version')(payload);
  if (currentSaga) {
    yield cancel(currentSaga.task);
  }
  const sagaLoader = sagas[currentMethodVersion];

  if (!sagaLoader) {
    throw new Error(`No CreditCardSaga for method version ${currentMethodVersion}`);
  }
  const { default: saga } = yield sagaLoader();
  currentSaga = {
    version: currentMethodVersion,
    task: yield fork(saga),
  };
}

const selectSagaVersion = (sagas: SagaLoaderSelection) => {
  return function* doSelectSagaVersion() {
    const currentMethodVersion = yield select(currentMethodVersionSelector);
    const currentMethodType = yield select(currentMethodTypeSelector);
    const isCard = ALLOWED_METHOD_TYPES[currentMethodType];

    if (currentSaga) {
      yield cancel(currentSaga.task);
    }
    if (!isCard) {
      return;
    }

    const sagaLoader = sagas[currentMethodVersion];
    if (!sagaLoader) {
      throw new Error(`No CreditCardSaga for method version ${currentMethodVersion}`);
    }

    const { default: saga } = yield sagaLoader();
    currentSaga = {
      version: currentMethodVersion,
      task: yield fork(saga),
    };

    yield put(ccGetDetailsAction());
  };
};

function* getInitialData({ api, paymentFlow }, { payload: { isTokenized = false } = {} }) {
  const shouldDoInit = yield select(shouldInitByConvention);
  if (shouldDoInit) {
    // do Init by convention
    yield put(ccStartAction);
    return yield put(
      genInitByConvention({
        pageName: 'card',
        successAction: ccInitByConventionSuccess,
        failureAction: ccErrorAction,
      }),
    );

    // If not perform init by convention, do the previous hack
  } else {
    yield getMethods({ api, paymentFlow });
    let methods;

    // getMethods saga sometimes yields before the data is registered in redux state so we need to have a poller to check
    while (true) {
      methods = yield select(methodsSelector);
      if (!methods || !methods.length) {
        yield delay(100);
      } else {
        break;
      }
    }
    let method;
    if (methods) {
      if (isTokenized) {
        const ti = yield select(cardTokenId);
        method = methods.find(
          ({ methodType, tokenized, metaData: { tokenId } = {} }) =>
            methodType === 'CARD' && tokenized === true && tokenId === ti,
        );
      } else {
        method = methods.find(({ methodType, tokenized }) => methodType === 'CARD' && tokenized === false);
      }
      // If there is no CARD method, go to methods page
      if (!method) {
        const url = populateParams(paths.PAYMENT_SELECTION, {
          appId: getAppId(),
        });
        window.location.href = url + window.location.search;
        return;
      }

      yield put(psSetMethodAction({ ...method, skipClearGlobalError: true }));
    }
  }
}

function* initByConventionSuccess({ analytics }, { payload }) {
  yield [
    put(
      ccInitByConventionSelectSaga({
        ...payload,
        methodType: `CARD-${payload.version}`,
      }),
    ),
    put(ccInitByConventionSuccess),
    put(ccSuccessAction(payload)),
  ];
}

const makeMainSaga = ({ behavior, ...params }) =>
  function* creditCardSaga(): Generator<void, void, void> {
    const { analytics } = params;
    yield takeLatest(psSetMethodAction, behavior.selectSagaVersion, params);
    yield takeLatest(ccInitByConventionSelectSaga, behavior.selectSagaForInitByConvention, params);
    yield takeLatest(ccDataErrorActions, behavior.handleCCDataError, params);
    yield takeLatest(ccGetInitialData, behavior.getInitialData, params);
    yield takeLatest(ccInitByConventionSuccess, behavior.initByConventionSuccess, params);

    yield takeLatest(ccAttemptSubmitFormAction, analytics.trackPaymentSubmission(methodTypes.creditCard));
  };

export default makeMainSaga({
  analytics: sharedAnalyticsService,
  behavior: {
    selectSagaVersion: selectSagaVersion(sagas),
    handleCCDataError,
    getInitialData,
    initByConventionSuccess,
    selectSagaForInitByConvention,
  },
  api: concreteApi,
  paymentFlow: paymentFlowService,
});

export const behaviorForTesting = {
  selectSagaVersion,
  makeMainSaga,
};
