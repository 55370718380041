// @flow

import { put, apiAction } from './shared';
import type { ApiCallOptions } from './shared';

type PaymentDetailsV1 = {
  id: string,
  paymentServiceProvider: string,
  hash: string,
  isTokenised: boolean,
  tokenId: string,
  subtype: ?string,
  frontendAttemptId: string,
};

type PaymentDetailsV2 = {
  tokenId: string,
  encryptedCardData: string,
  storeData: boolean,
  subtype: string,
};

const doExecutePayment = put('/:paymentId/experience/result/card');

export const executePayment = ({ id, ...body }: PaymentDetailsV1 | PaymentDetailsV2, options: ApiCallOptions = {}) => {
  if (options.version === 'V2.1') {
    const additionalHeaders =
      body?.paymentServiceProvider?.toUpperCase() === 'PAYU'
        ? {
            'Browser-Width': screen.width.toString(),
            'Browser-Height': screen.height.toString(),
            'Browser-Color-Depth': screen.colorDepth.toString(),
            'Browser-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
          }
        : {};

    return apiAction(options.actions.submit, {
      body: {
        encryptedCardData: body.cardData || body.encryptedCardData,
        storeData: Boolean(body.storeData),
        subtype: body.cardType || body.cardtype || body.subtype,
        tokenId: body.tokenId,
        tokenized: Boolean(body.tokenized),
      },
      additionalHeaders: additionalHeaders,
    });
  }

  return doExecutePayment(
    { paymentId: id },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      ...options,
    },
  );
};

export default executePayment;
