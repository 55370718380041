import { matchPath } from 'react-router-dom';

import { APP_ID } from '../pathConstants';
import { routes } from '../routes';

export const getCurrentPathMatch = (path = `/:${APP_ID}`, exact = false, strict = false) =>
  matchPath(window.location.pathname, { path, exact, strict });

export const getCurrentPathParams = (...args) => (getCurrentPathMatch(...args) || {}).params;

export const getCurrentRoute = () =>
  routes.find(({ path, exact, strict }) => {
    const match = getCurrentPathMatch(path, exact, strict);
    return !!match;
  });
