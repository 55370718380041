import React from 'react';

import { Paragraph6 } from '@olxui/core-v10/dist/core/Typography';

import { getMessage } from '../../helpers/lexemes';
import * as paths from '../../pathConstants';
import Link from '../Link';

type Props = {
  type: string,
};

const AboutLink = ({ type }: Props) => (
  <Link to={paths.ABOUT} data-test="about-us-page" textLink trackingParams={{ linkType: type }}>
    <Paragraph6 color="text-global-secondary">{getMessage('About.payments.title')}</Paragraph6>
  </Link>
);

export default AboutLink;
