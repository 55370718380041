import useAxios from 'axios-hooks';

import { UsePaymentStatusReturn } from './types';

export const usePaymentStatusRequest = (url: string): UsePaymentStatusReturn => {
  const [{ data, error }, request] = useAxios(
    {
      url,
    },
    { manual: true },
  );

  const handleGetStatusRequest = () => {
    if (!url) return;

    request();
  };

  return { data, error, handleGetStatusRequest };
};
