import { flow, get } from 'lodash/fp';
import { stringify } from 'query-string';
import { goBack as routerGoBackAction, replace, push } from 'react-router-redux';
import { delay } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import determinePaymentStatusRedirection from '../../helpers/determinePaymentStatusRedirectionPath';
import getSearchParams from '../../helpers/getSearchParams';
import { getCurrentPathParams } from '../../helpers/matchUtils';
import populateParams from '../../helpers/populateParams';
import postForm from '../../helpers/postForm';
import * as paths from '../../pathConstants';
import paymentMethodsSelector, {
  preselectedMethodFlavor as isPreselectedSelector,
} from '../../selectors/paymentMethods';
import { location as locationSelector } from '../../selectors/routing';
import { setGlobal } from '../actions/error';
import { clearStateAction, goBack as trackedGoBackAction } from '../actions/global';

type RedirectOptions = {
  replace: boolean,
  search: string,
};

const mergeParams = (...args) => {
  const params = args.reduce((acc, item) => {
    return {
      ...acc,
      ...(typeof item === 'string' ? getSearchParams(item) : item),
    };
  }, {});

  return stringify(params);
};

const internalRedirect = (targetPathname: string) =>
  function* doInternalRedirect(redirectOptions: RedirectOptions = {}) {
    if (!targetPathname) {
      return;
    }

    const location = yield select(locationSelector);
    const [pathname, targetSearch] = populateParams(targetPathname, getCurrentPathParams()).split('?');

    if (pathname === location.pathname) {
      return;
    }

    const redirectActionCreator = redirectOptions.replace ? replace : push;
    const { init, token } = getSearchParams(location.search);
    const search = mergeParams(
      targetPathname.includes('interstitial') ? { init } : { init, token },
      targetSearch,
      redirectOptions.search,
    );

    yield put(
      redirectActionCreator({
        ...location,
        search,
        pathname,
      }),
    );
  };

function* externalRedirect(pathname: string) {
  if (pathname) {
    yield call(delay, 100); // So that spinners animates to tick!!!!!!!
    window.location = pathname;
  }
}

// TODO redirectInFlow, redirectToIntegrator and redirectToCompletedUrl should be one.
export function* redirectInFlow(action) {
  const {
    payload: {
      body: { redirectUrl },
    },
  } = action;
  yield externalRedirect(redirectUrl);
}

export function* redirectToIntegrator(action) {
  const {
    payload: { completedUrl, redirectUrl },
  } = action;
  yield externalRedirect(completedUrl || redirectUrl);
}

export function* cancelExperience() {
  const { backUrl } = yield select(paymentMethodsSelector);

  if (!backUrl) {
    const err = new Error("Not found 'back url' when canceling the experience");
    window.newrelic.noticeError(err);
    throw err;
  }

  yield externalRedirect(backUrl);
}

export function redirectToCompletedUrl(action) {
  const {
    payload: {
      entity: { completedUrl },
    },
  } = action;
  externalRedirect(completedUrl);
}

export function redirectViaFormSubmit({ payload: { requestUrl, request } }) {
  postForm(requestUrl, request);
}

export const redirectToPaymentSelection = internalRedirect(paths.PAYMENT_SELECTION);
export const redirectToCreditCard = internalRedirect(paths.CARD);
export const redirectToPaymentStatus = internalRedirect(paths.PAYMENT_RESULT);

function* clearState() {
  yield put(clearStateAction());
}

export function* goBack() {
  yield put(trackedGoBackAction());
  yield put(routerGoBackAction());
}

export type PaymentFlowType = 'CARD' | 'INSTANT_BANK_TRANSFER' | 'SMS';

const useBodyAsPayload = ({ payload: { body } }) => ({ payload: body });

const handlePaymentFlowFinishedWith = (behavior) =>
  flow(useBodyAsPayload, function* doHandlePaymentFlowFinished(action) {
    yield behavior.clearState();
    yield behavior.redirectToIntegrator(action);
  });

export const handlePaymentFlowFinished = handlePaymentFlowFinishedWith({
  clearState,
  redirectToIntegrator,
});

const handlePaymentErrorWith = (behavior) =>
  function* doHandlePaymentError(action) {
    yield behavior.clearState();
    // HTTP Gone
    if (action.payload.status === 410) {
      yield behavior.redirectToCompletedUrl(action);
    } else {
      yield behavior.redirectToPaymentSelection();
    }
  };

export const handlePaymentError = handlePaymentErrorWith({
  clearState,
  redirectToCompletedUrl,
  redirectToPaymentSelection,
});

const REDIRECTION_MESSAGES = ['RETRY_METHOD', 'TRY_OTHER_METHOD', 'PENDING', 'UNKNOWN'];

export const shouldRedirect = flow(
  get('payload.body.message'),
  (message) => !(message && REDIRECTION_MESSAGES.indexOf(message) === -1),
);

export function* redirectOnStatusTimeout({ payload: { status } }) {
  const isSelectedMethodFlavor = yield select(isPreselectedSelector);
  if (isSelectedMethodFlavor) {
    yield put(setGlobal('generic'));
    yield redirectToCreditCard({ payload: { status } });
  } else {
    if (status === 'WAITING_PSP_ACTION') {
      yield put(setGlobal('chooseAnotherMethod'));
    }

    const url = determinePaymentStatusRedirection(status);

    yield internalRedirect(url)({ replace: true });
  }
}

export function* redirect({ payload: { url, ...redirectOptions } }) {
  if (url && url.indexOf('http') === 0) {
    const [pathname, search] = url.split('?');
    const uri = `${pathname}?${mergeParams(search, redirectOptions.search)}`;

    yield externalRedirect(uri);
  } else {
    yield internalRedirect(url)(redirectOptions);
  }
}
