export default (
  value: Number,
  { symbol = '$', pattern = '!#', separator = '.', decimal = ',', precision = 2 } = {},
) => {
  value = round(value, precision);
  const floor = Math.floor(value);
  const rest = getDecimals(value);
  const formatted = String(floor).replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  const result = Number(rest) && precision ? `${formatted}${decimal}${rest}` : formatted;

  return pattern.replace('!', symbol).replace('#', isNaN(value) ? '' : result);
};

function round(value, precision) {
  return +(Math.round(value + `e+${precision}`) + `e-${precision}`);
}

function getDecimals(value) {
  if (Number.isInteger(value)) {
    return '0';
  } else {
    const decimals = String(value).split('.')[1];
    return decimals ? decimals.padEnd(2, '0') : decimals;
  }
}
