import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { goBack } from 'react-router-redux';
import { compose, pure, withHandlers } from 'recompose';

import { goBack as trackGoBack, cancelExperienceAction } from '../../redux/actions/global';
import { redirectToPaymentMethodsAction } from '../../redux/actions/routing';
import headerSelector from '../../selectors/header';
import Icon from '../Icon/Icon';

function BackButton({ handleBackClick, history, ...rest }) {
  return <Icon id="arrow-left" size="24" className="rtl-icon pointer" onClick={handleBackClick} {...rest} />;
}

export default compose(
  withRouter,
  connect(headerSelector, {
    goBack,
    trackGoBack,
    cancelExperience: cancelExperienceAction,
    redirectToPaymentMethods: redirectToPaymentMethodsAction,
  }),
  withHandlers({
    handleBackClick:
      ({
        cancelExperience,
        goBack,
        trackGoBack,
        shouldUseCancelUrl,
        shouldRedirectToPaymentMethodsPage,
        redirectToPaymentMethods,
      }) =>
      (e) => {
        e.preventDefault();
        trackGoBack();
        if (shouldUseCancelUrl) {
          cancelExperience();
        } else if (shouldRedirectToPaymentMethodsPage) {
          redirectToPaymentMethods();
        } else {
          goBack();
        }
      },
  }),
  pure,
)(BackButton);
