/*go, goBack, goForward, */
import { matchPath } from 'react-router-dom';
import { replace, push } from 'react-router-redux';

import * as paths from '../pathConstants';
import { routes } from '../routes';

export const navigate = (url, useReplace, state) => {
  if (url && url.indexOf('http') === 0) {
    externalRedirect(url);
  } else {
    internalRedirect(url, useReplace, state);
  }
};

const externalRedirect = (url) => {
  window.location = url;
};

const internalRedirect = (url, useReplace, state) => {
  const routerAction = useReplace ? replace : push;
  routerAction(url, state);
};

type PageTitle = {
  title: string,
  description?: string,
};

const INSTANT_BANK_TRANSFER_TEXT = 'App.payment.INSTANT_BANK_TRANSFER.title';

const pageTitles: { [string]: PageTitle } = {
  [paths.INDEX]: {
    title: 'App.paymentSelectionPage.title',
  },
  [paths.PAYMENT_SELECTION]: {
    title: 'App.paymentSelectionPage.title',
  },
  [paths.INTERSTITIAL]: {
    title: 'App.ConfirmPayment.title',
  },
  [paths.PAYMENT_RESULT]: {
    title: 'App.paymentResult.title',
  },
  [paths.CARD]: {
    title: 'App.payment.CARD.title',
  },
  [paths.STORED_CARD]: {
    title: 'App.payment.STORED_CARD.title',
  },
  [paths.ABOUT]: {
    title: 'About.payments.title',
  },
  [paths.SMS]: {
    title: 'App.payment.SMS.title',
  },
  [paths.INSTANT_BANK_TRANSFER]: {
    title: INSTANT_BANK_TRANSFER_TEXT,
  },
  [paths.BANK_TRANSFER_RESULT]: {
    title: 'App.payment.BANK_TRANSFER.title',
  },
  [paths.OVER_THE_COUNTER_RESULT]: {
    title: 'App.payment.OVER_THE_COUNTER.result.title',
  },
  [paths.MAINTENANCE_PAGE]: {
    title: 'App.payment.MAINTENANCE.title',
  },
  [paths.GENERIC]: {
    [paths.IBT]: {
      title: INSTANT_BANK_TRANSFER_TEXT,
    },
    [paths.OTC]: {
      title: INSTANT_BANK_TRANSFER_TEXT,
    },
    [paths.BANK_TRANSFER]: {
      title: 'PSEForm.title',
    },
  },
};

export const getTitleAndSubtitle = (pathname) => {
  const route = routes.find((item) => matchPath(pathname, item));

  // In the case of the generic page, we want to get the specific keys for the page
  // and not a generic key. We do this by having a nested object with specific paths
  if (route && route.path === paths.GENERIC) {
    const titleKey = Object.keys(pageTitles[paths.GENERIC]).find((path) =>
      matchPath(pathname, {
        path,
        exact: true,
      }),
    );
    return pageTitles[paths.GENERIC][titleKey || ''] || {};
  }

  return pageTitles[route ? route.path : ''] || {};
};
