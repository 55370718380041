export const newRelicAction = (actionName, ...args) => {
  if (window.newrelic && typeof window.newrelic[actionName] === 'function') {
    window.newrelic[actionName](...args);
  }
};

export const addPageAction = (actionName, data) => {
  newRelicAction('addPageAction', actionName, data);
};

export const PAGE_ACTION_NAMES = {
  API: 'APICommunication',
  API_START: 'APICommunication',
};

export const API_STATUS = {
  FAIL: 'FAIL',
  SUCCESS: 'SUCCESS',
};
