// @flow

import styled from '@emotion/styled';

import mq, { breakpoints } from '../styles/mediaQueries';

export type ContainerProps = {
  type?: 'narrow' | 'wide',
};

const containerStyleTypes = {
  narrow: `
    max-width: 448px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  `,
  wide: `
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;

    ${mq.gt('phablet')} {
      width: ${getBreakpoint('phablet')};
    }
    ${mq.gt('tablet')} {
      width: ${getBreakpoint('tablet')};
    }
    ${mq.gt('desktop')} {
      width: ${getBreakpoint('desktop')};
    }
    ${mq.gt('desktopLarge')} {
      width: ${getBreakpoint('desktopLarge')};
    }
  `,
  def: null,
};

const Container = styled.div`
  ${({ type = '' }: ContainerProps) => containerStyleTypes[type] || containerStyleTypes.narrow};
`;

export default Container;

function getBreakpoint(breakpoint) {
  return `${parseInt(breakpoints[breakpoint], 10) - 32}px`;
}
