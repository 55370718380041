import parseInitParams from '../helpers/parseInitParams';
import importByReflection from './importByReflection';

const RTL_LOCALES = ['ar-lb', 'ar-eg'];
// Map unrecognized or malformed ISO 3166
const ISORules = {
  'in-id': 'id',
};

const getLocale = () => {
  const { result } = parseInitParams();
  const locale = result.locale.replace('_', '-').toLowerCase();

  return ISORules[locale] || locale;
};

export const getAppConfig = async () => {
  try {
    const locale = getLocale();
    const [lang, region] = locale.split('-');

    const [appConfigModule, messages, links] = await Promise.all([
      importByReflection(locale, region, 'formats'),
      importByReflection(locale, lang, 'translations'),
      importByReflection(locale, region, 'links'),
    ]);

    return {
      ...appConfigModule,
      isRTL: RTL_LOCALES.includes(locale),
      lang,
      links,
      messages,
    };
  } catch (err) {
    throw new Error(`Error while parsing init params: ${err}`);
  }
};
