// @flow

import { get } from 'lodash/fp';
import { takeLatest, put } from 'redux-saga/effects';

import * as api from '../../api';
import { otcStartAction } from '../actions/overTheCounter';
import { psSetMethodAction } from '../actions/paymentStatus';
import { redirectViaFormSubmit } from './paymentFlowService';

const ALLOWED_METHOD_TYPES = {
  'OVER_THE_COUNTER-V2': true,
  'MOBILE_WALLET-V2': true,
};

// TODO refactor dependency injection.
function* initialize(params, action) {
  const methodType = get('payload.methodType', action);
  if (!ALLOWED_METHOD_TYPES[methodType]) {
    return;
  }

  yield put(otcStartAction());

  const apiCallOptions = get('payload', action);
  const { json = {} } = yield params.api.shared.get('')({}, apiCallOptions);

  const requestUrl = get('action.uri', json);
  const request = get('action.params', json);

  if (json === null) {
    return;
  }
  yield redirectViaFormSubmit({ payload: { requestUrl, request } });
}

const makeMainSaga = ({ behavior, ...params }) =>
  function* overtTheCounterPaymentSaga() {
    yield takeLatest(psSetMethodAction, behavior.initialize, params);
  };

export default makeMainSaga({
  api,
  behavior: {
    initialize,
  },
});

export const behaviorForTesting = {
  initialize,
  makeMainSaga,
};
