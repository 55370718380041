// @flow

import React from 'react';

import styled from '@emotion/styled';
import { PageHeader } from '@olxui/core-v10/dist/core/PageHeader/PageHeader';
import { Headline2 } from '@olxui/core-v10/dist/core/Typography';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';

import getAppId from '../../helpers/getAppId';
import { getMessage } from '../../helpers/lexemes';
import { getTitleAndSubtitle } from '../../helpers/route';
import { useAppIdStyle } from '../../hooks';
import headerSelector from '../../selectors/header';
import mq from '../../styles/mediaQueries';
import BackButton from '../BackButton';
import Container from '../Container';
import Icon from '../Icon/Icon';

type Props = {
  children: React.ReactNode;
  location: { pathname: string };
  description: string;
  showHeaderControls: boolean;
  showHeader: boolean;
  showBackButton: boolean;
};

export const Header = ({ showBackButton, showHeader, location: { pathname } = {} as any }: Props) => {
  const topClassStyle = useAppIdStyle('HeaderTop');
  const backClassStyle = useAppIdStyle('BackButton');


  if (!showHeader) {
    return null;
  }

  const { title } = getTitleAndSubtitle(pathname);

  return (
    <React.Fragment>
      <HeaderTop className={topClassStyle}>
        <PageHeader.Left>
          <Logo app={getAppId()} id="logo" color="background-brand-secondary" size="72" />
          {showBackButton && <BackButton data-test="header-back-button" />}
        </PageHeader.Left>
      </HeaderTop>

      <HeaderBottom type="wide">
        {showBackButton && isMobile && (
          <BackButton className={backClassStyle} data-test="header-back-button" />
        )}
        <Headline2 fontWeight="bold" data-test="page-title">
          {getMessage(title)}
        </Headline2>
      </HeaderBottom>
    </React.Fragment>
  );
};

const Logo = styled(Icon)`
  display: none;
  ${mq.gt('phablet')} {
    display: block;
  }
`;

const HeaderTop = styled(PageHeader)`
  ${mq.gt('phablet')} {
    background-color: ${({ theme }) => theme.colors['background-brand-primary']} !important;
    > div {
      height: 72px;
    }
    [data-test='header-back-button'] {
      display: none;
    }
  }
`;

const HeaderBottom = styled(Container)`
  display: flex;
  align-items: center;

  [data-test='header-back-button'] {
    display: none;
  }

  ${mq.lt('phablet')} {
    margin: 0 0 16px 0;
  }

  ${mq.gt('phablet')} {
    margin-top: 40px;
    margin-bottom: 40px;
    [data-test='header-back-button'] {
      display: block;
    }
    h2 {
      margin: 0 16px;
    }
  }
`;

// TODO refactor and introduce a more intelligent action that will include tracking etc.
export default compose(connect(headerSelector), pure)(Header as any) as any;
