// @flow

import { get, getOr } from 'lodash/fp';
import { createSelector, createStructuredSelector } from 'reselect';

import creditCardType from '../helpers/creditCardType';
import determinePaymentStatusRedirection from '../helpers/determinePaymentStatusRedirectionPath';
import { paymentId } from './global';
import { showFullPageLoader } from './loader';
import { methods } from './paymentMethods';
import { isInStoredCardPath, queryParams } from './routing';

const main = get('paymentStatus');

const status = createSelector(main, get('status'));

const successUrl = createSelector(main, get('status'));

const timedOut = createSelector(main, get('timedOut'));

const redirectAwayUrl = createSelector(status, timedOut, ({ status }, hasTimedOut) =>
  hasTimedOut ? determinePaymentStatusRedirection(status) : undefined,
);

export const allowPayment = createSelector(main, get('allowPayment'));

export const currentMethod = createSelector(main, get('currentMethod'));
export const selectedMethod = createSelector(queryParams, methods, currentMethod, ({ tokenId }, allMethods, method) => {
  if (method) {
    return method;
  }

  if (tokenId && allMethods) {
    return allMethods.find(
      ({ tokenized, metaData = {}, context = {} }) =>
        tokenized && [metaData.tokenId, context.tokenId].includes(tokenId),
    );
  }

  return undefined;
});

export const currentMethodVersion = createSelector(selectedMethod, getOr('V1', 'version'));

export const currentMethodMetaData = createSelector(selectedMethod, ({ tokenized, metaData, context = {} } = {}) => {
  const { cardType } = metaData || context;
  if (tokenized) {
    const cardTypeInfo = creditCardType.getTypeInfo(cardType.toLowerCase());
    return {
      ...metaData,
      ...context,
      card: cardTypeInfo || {},
    };
  }

  return metaData;
});

export const shouldShowCvvModal = createSelector(selectedMethod, isInStoredCardPath, (method, isInPath) => {
  return isInPath && getOr(false, 'tokenized', method);
});

export const currentMethodType = createSelector(selectedMethod, get('methodType'));

export const interstitialVisible = createSelector(main, get('interstitialVisible'));

export const shouldRedirectToInterstitial = createSelector(main, get('shouldRedirectToInterstitial'));

export const statusRequestConfig = createSelector(main, get('statusRequestConfig'));

export default createStructuredSelector({
  status,
  successUrl,
  paymentId,
  interstitialVisible,
  shouldRedirectToInterstitial,
  showFullPageLoader,
  redirectAwayUrl,
});
